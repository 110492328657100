import React, { useState } from 'react'
import { connect } from 'react-redux'

//ACTION METHODS
import { updateTemplate } from '../../redux/actions/docTypeActions'

//COMPONENTS
import { InputGroup,FormInput,Dropdown,DropdownToggle,DropdownMenu,DropdownItem } from 'shards-react'


const FieldSelectionDropdown = (props) => {

  const {FLD,tag,update,disabled} = props
  const [visible,toggleVisible] = useState(false)
  const [value,updateValue] = useState('')
  const [activeIdx,updateActiveIdx] = useState(-1)

  if(FLD === null) {
    update({[tag]: {}})
  }
  else if(!FLD.SLNS) {
    update({[tag]: {...FLD,SLNS: {OPTS: new Array()}}})
  }

  return (
      <InputGroup>
          <FormInput 
            value={value}
            disabled={disabled || (FLD.SLNS !== null && FLD.SLNS.OPTS.length === 30)}
            onChange={(e) => updateValue(e.target.value)}
            onKeyDown={(e) => {
              if(e.key === 'Enter') {
                e.preventDefault()
                if(!visible) toggleVisible(true)
                //ADD SELECTION OPTION, CLEAR INPUT
                if(activeIdx > -1) {

                  update({[tag]: {
                    ...FLD,
                    SLNS: {
                      ...FLD.SLNS,
                      OPTS: Object.assign([...FLD.SLNS.OPTS], {[activeIdx]: value})
                    }
                  }})
                }else {
                  update({[tag]: {
                    ...FLD,
                    SLNS: {
                      ...FLD.SLNS,
                      OPTS: [...FLD.SLNS.OPTS, value]
                    }
                  }})
                }
                updateActiveIdx(-1)
                updateValue('')
              }
            }}
          />
          <Dropdown
            open={visible}
            toggle={(e) => {
              toggleVisible(!visible)
            }}
            addonType="append"
          >
            <DropdownToggle disabled={((FLD !== null && (FLD.SLNS !== null && FLD.SLNS !== undefined)) && FLD.SLNS.length === 0) || disabled} theme="white" caret>Values List</DropdownToggle>
            <DropdownMenu className={"dropdown-scroll"} small right>
              {(FLD.SLNS !== null && (FLD.SLNS.OPTS !== undefined && FLD.SLNS.OPTS !== null)) && FLD.SLNS.OPTS.map((opt,idx) => (
                <DropdownItem 
                onClick={(e) => {
                  updateValue(opt)
                  updateActiveIdx(idx)
                }}
                key={idx}>{opt}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </InputGroup>
  )
}

FieldSelectionDropdown.defaultProps = {
  FLD: {
    SLNS: []
  },
  tag: '',
  disabled: false
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = (dispatch) => ({
  update: (obj) => dispatch(updateTemplate(obj))
})

export default connect(null,mapDispatchToProps)(FieldSelectionDropdown)