import getSidebarItems from '../../data/sidebar-nav-items'
import store from '../store'

export const INIT_SIDEBAR_ITEMS = "INIT_SIDEBAR_ITEMS"
export const TOGGLE_SIDEBAR_DROPDOWN = "TOGGLE_SIDEBAR_DROPDOWN"
export const TOGGLE_NAVBAR_USER_DROPDOWN = "TOGGLE_NAVBAR_USER_DROPDOWN"
export const TOGGLE_REPO_DROPDOWN = "TOGGLE_REPO_DROPDOWN"
export const END_APP_LOADER = "END_APP_LOADER"
export const TOGGLE_NOTIFICATION_DROPDOWN = "TOGGLE_NOTIFICATION_DROPDOWN"
export const TOGGLE_ADD_DIR_COLLAPSE = "TOGGLE_ADD_DIR_COLLAPSE"
export const TOGGLE_HIDE_TOOLTIPS = "TOGGLE_HIDE_TOOLTIPS"
export const SET_NAVBAR_SEARCH_HANDLER = "SET_NAVBAR_SEARCH_HANDLER"
export const SET_SEARCH_TIMER = "SET_SEARCH_TIMER"

/**
 * INITIALIZES THE LIST OF SIDEBAR MENU ITEMS IN STATE FROM PRE-BUILT LIST STORED IN FILE
 */
export const initSidebarItems = () => ({
    type: INIT_SIDEBAR_ITEMS,
    payload: getSidebarItems()
})

export const toggleNavbarUserDropdown = () => ({
    type: TOGGLE_NAVBAR_USER_DROPDOWN
})

export const endLoader = () => ({
    type: END_APP_LOADER
})

export const toggleRepoDropdown = () => ({
    type: TOGGLE_REPO_DROPDOWN
})

export const toggleNotificationDropdown = () => ({
    type: TOGGLE_NOTIFICATION_DROPDOWN
})

export const toggleAddDirCollapse = () => ({
    type: TOGGLE_ADD_DIR_COLLAPSE
})

export const toggleHideTooltips = (b) => ({
    type: TOGGLE_HIDE_TOOLTIPS,
    action: b
})

export const setNavbarSearchHandler = (func) => {
    // console.log('func: ', func)
    return {
        type: SET_NAVBAR_SEARCH_HANDLER,
        payload: func
    }
}

export const setSearchTimer = (func) => {
    clearTimeout(store.getState().layout.timer)
    return {
        type: SET_SEARCH_TIMER,
        payload: func
    }
}


/**
 * TOGGLES THE DROPDOWN SELECTED IN THE SIDEBAR MENU ITEMS LIST TO EITHER OPEN OR CLOSE
 * @param {SidebarItem} item 
 */
export const toggleSidebarDropdown = (item) => {
    // console.log('item: ', item)
    let sidebarItems = store.getState().layout.sidebarItems
    let navGroupIdx = null
    let navItemIdx = null

    sidebarItems.forEach((navItem, _idx) => {
        const __idx = navItem.items.findIndex((__i) => {return __i.title === item.title})
        if (__idx !== -1) {
          navGroupIdx = _idx;
          navItemIdx = __idx;
        }
    })
    sidebarItems[navGroupIdx].items[navItemIdx].open = !sidebarItems[navGroupIdx].items[navItemIdx].open

    sidebarItems = sidebarItems.map(i => {
        i.items = i.items.map((_i, idx) => {
            if (idx !== navItemIdx) {
            _i.open = false
            }
            return _i
        })
    return i
    })

    return ({
        type: TOGGLE_SIDEBAR_DROPDOWN,
        payload: sidebarItems
    })
}

export const toggleTooltips = () => {
    return async dispatch => {
        let props = (localStorage.getItem('props') !== null ? JSON.parse(localStorage.getItem('props')) : null)
        if(props !== null) {
            if(props.TOGGLE_HIDE_TOOLTIPS !== undefined) {
                let b = props.TOGGLE_HIDE_TOOLTIPS
                if(b === true || b === 'true') {
                    props.TOGGLE_HIDE_TOOLTIPS = false
                }else {
                    props.TOGGLE_HIDE_TOOLTIPS = true
                }
                localStorage.setItem('props',JSON.stringify(props))
                dispatch(toggleHideTooltips(props.TOGGLE_HIDE_TOOLTIPS))
            }
        }else {
            localStorage.setItem('props', JSON.stringify({TOGGLE_HIDE_TOOLTIPS: true}))
            dispatch(toggleHideTooltips(true))
        }
    }
}


export const initLayoutProps = () => {
    return async dispatch => {
        let props = (localStorage.getItem('props') !== null ? JSON.parse(localStorage.getItem('props')) : null)
        if(props !== null) {
            /**
             * TOGGLE TOOLTIPS
             */
            if(props.TOGGLE_HIDE_TOOLTIPS !== undefined) {
                if(props.TOGGLE_HIDE_TOOLTIPS) 
                    dispatch(toggleHideTooltips())
            }
        }
    }
}
