import React from 'react'
import { connect } from 'react-redux'
import { FormCheckbox,Card,CardHeader,CardBody,Col,Row,Form,FormInput } from 'shards-react'
import { updateTemplate } from '../../redux/actions/docTypeActions'


const TemplateSettings = (props) => {

  const {template,update} = props

  return (
    (template.EXPS !== undefined && 
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Template Settings</h6>
        </CardHeader>
        <CardBody>
          <Col>
            <Form>
              <Row form>
                <Col lg="12">
                  <Row>
                    <label htmlFor="dtName">Template Name</label>
                  </Row>
                  <Row form className="mb-4">
                    <FormInput
                      id="dtName"
                      placeholder="Enter name here..."
                      value={template.NM}
                      onChange={(e) => update({NM: e.target.value})}
                    />
                  </Row>
                  <Row form>
                    {/* AUDIT */}
                    <Col lg="3">
                      <FormCheckbox
                        small
                        checked={template.AUDIT.toString() === 'true'}
                        onClick={(e) => update({AUDIT: !(template.AUDIT.toString() === 'true')})}
                      >audit</FormCheckbox>
                    </Col>
                    {/* COMPRESSED */}
                    <Col lg="3">
                      <FormCheckbox
                        small
                        checked={template.CMP.toString() === 'true'}
                        onClick={(e) => update({CMP: !(template.CMP.toString() === 'true')})}
                      >compressed</FormCheckbox>
                    </Col>
                    {/* ENCRYPTED */}
                    <Col lg="3">
                      <FormCheckbox
                        small
                        checked={template.ENCD.toString() === 'true'}
                        onClick={(e) => update({ENCD: !(template.ENCD.toString() === 'true')})}
                      >encrypted</FormCheckbox>
                    </Col>
                    {/* EXPIRES */}
                    <Col lg="3">
                      <FormCheckbox
                        small
                        checked={template.EXPS.toString() === 'true'}
                        onClick={(e) => update({EXPS: !(template.EXPS.toString() === 'true')})}
                      >expires</FormCheckbox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </CardBody>
      </Card>
    )
  )
}

TemplateSettings.defaultProps = {
  template: {
    AUDIT: false,
    ENCD: false,
    CMP: false,
    EXPS: false
  }
}

const mapStateToProps = (state) => ({
  template: state.doctype.DOCTP
})

const mapDispatchToProps = (dispatch) => ({
  update: (obj) => dispatch(updateTemplate(obj))
})

export default connect(mapStateToProps,mapDispatchToProps)(TemplateSettings)