
import axios from '../../connection/axiosHandler'
import url from '../../connection/url'
import { 
    validateSessionError,
    authenticateFailure 
} from './authActions'

import store from '../store'

export const FETCH_ACCT_BEGIN = "FETCH_ACCT_BEGIN"
export const FETCH_ACCT_SUCCESS = "FETCH_ACCT_SUCCESS"
export const FETCH_ACCT_FAILURE = "FETCH_ACCT_FAILURE"

export const FETCH_ACCT_USERS_BEGIN = "FETCH_ACCT_USERS_BEGIN"
export const FETCH_ACCT_USERS_SUCCESS = "FETCH_ACCT_USERS_SUCCESS"
export const FETCH_ACCT_USERS_FAILURE = "FETCH_ACCT_USERS_FAILURE"

export const FETCH_ACCT_REPOS_BEGIN = "FETCH_ACCT_REPOS_BEGIN"
export const FETCH_ACCT_REPOS_SUCCESS = "FETCH_ACCT_REPOS_SUCCESS"
export const FETCH_ACCT_REPOS_FAILURE = "FETCH_ACCT_REPOS_FAILURE"

export const FETCH_ACCT_DOCTPS_BEGIN = "FETCH_ACCT_DOCTPS_BEGIN"
export const FETCH_ACCT_DOCTPS_SUCCESS = "FETCH_ACCT_DOCTPS_SUCCESS"
export const FETCH_ACCT_DOCTPS_FAILURE = "FETCH_ACCT_DOCTPS_FAILURE"

export const UPDATE_ACCT_USERS_PAGINATION = "UPDATE_ACCT_USERS_PAGINATION"
export const UPDATE_ACCT_DOCTPS_PAGINATION = "UPDATE_ACCT_DOCTPS_PAGINATION"

export const updateAccountUsersPaginationParams = (params) => ({
    type: UPDATE_ACCT_USERS_PAGINATION,
    payload: params
})

export const updateAccountDocTypesPaginationParams = (params) => ({
    type: UPDATE_ACCT_DOCTPS_PAGINATION,
    payload: params
})

export const fetchAcctBegin = () => ({
    type: FETCH_ACCT_BEGIN
})

export const fetchAcctSuccess = (ACCT) => ({
    type: FETCH_ACCT_SUCCESS,
    payload: {ACCT}
})

export const fetchAcctFailure = (ERR) => ({
    type: FETCH_ACCT_FAILURE,
    payload: ERR
})

export const fetchAcctUsersBegin = () => ({
    type: FETCH_ACCT_USERS_BEGIN
})

export const fetchAcctUsersSuccess = (acct) => ({
    type: FETCH_ACCT_USERS_SUCCESS,
    payload: acct
})

export const fetchAcctUsersFailure = (ERR) => ({
    type: FETCH_ACCT_USERS_FAILURE,
    payload: ERR
})

export const fetchAcctReposBegin = () => ({
    type: FETCH_ACCT_REPOS_BEGIN
})

export const fetchAcctReposSuccess = (acct) => ({
    type: FETCH_ACCT_REPOS_SUCCESS,
    payload: acct
})

export const fetchAcctReposFailure = (ERR) => ({
    type: FETCH_ACCT_REPOS_FAILURE,
    payload: ERR
})

export const fetchAcctDocTypesBegin = () => ({
    type: FETCH_ACCT_DOCTPS_BEGIN
})

export const fetchAcctDocTypesSuccess = (doctps) => ({
    type: FETCH_ACCT_DOCTPS_SUCCESS,
    payload: doctps
})

export const fetchAcctDocTypesFailure = (ERR) => ({
    type: FETCH_ACCT_DOCTPS_FAILURE,
    payload: ERR
})

/**
 * fetchAccount calls to the sdx user to 
 * retrieve the account for the provided
 * User's Id. 
 */
export function fetchAccount() {
    return async dispatch => {
        let usr
        if(sessionStorage.getItem('USR') !== null ? (usr=JSON.parse(sessionStorage.getItem('USR')).USR) !== null : false) {
            dispatch(fetchAcctBegin())
            const response = await axios({
                baseURL: `${url}/accts/${usr.ACCT.ID}`,
                crossDomain: true,
                timeout: 200000,
                withCredentials: true,
                credentials: 'same-origin',
                responseType: 'json',
                method: 'get'
            })
            if(response.data.ERR !== null) {
                if(!validateSessionError(response.data.ERR)) {
                    dispatch(authenticateFailure())
                }
                dispatch(fetchAcctFailure())
            }else if(response.data.RSP.ACCT !== null) {
                dispatch(fetchAcctSuccess(response.data.RSP.ACCT))
            }else {
                dispatch(fetchAcctFailure('No Account Returned.'))
            }
        }else {
            dispatch(fetchAcctFailure("User's Account Id could not be located."))
        }
    }
}

/**
 * fetchAccountUsers calls to the sdx server 
 * for a list of the Account's available Users.
 * 
 * @param {Object} ID {ACCT: 0, TJT: ID, UI, FULL}
 */
export const fetchAccountUsers = (ID) => {
    return async dispatch => {
        if(ID.ACCT) {
            dispatch(fetchAcctUsersBegin())
            const response = await axios({
                baseURL: `${url}/ui/${ID.ACCT.ID}/users/pagination`,
                crossDomain: true,
                timeout: 200000,
                withCredentials: true,
                credentials: 'same-origin',
                responseType: 'json',
                method: 'post',
                data: {
                    PAGE: ID.PAGE || 0,
                    PAGE_SIZE: ID.PAGE_SIZE || 5
                }
            })
            if(response.data.ERR !== null) {
                if(!validateSessionError(response.data.ERR))
                    dispatch(authenticateFailure())
                dispatch(fetchAcctUsersFailure(response.data.ERR))
            }else if(response.data.RSP.USRS && response.data.RSP.PAGINATION) {
                dispatch(updateAccountUsersPaginationParams(response.data.RSP.PAGINATION))
                dispatch(fetchAcctUsersSuccess(response.data.RSP))
            }else {
                dispatch(fetchAcctUsersFailure('fetchAccountUsers.error: No account users found.'))
            }
        }else {
            dispatch(fetchAcctUsersFailure('fetchAccountUsers.error: No Acct provided.'))
        }
    }
}


/**
 * fetchAccountRepos calls to the sdx server 
 * for a list of the account's available Repositories.
 * 
 * @param {Object} ID {ACCT: 0, TJT: ID, UI, FULL}
 */
export const fetchAccountRepos = (ID) => {
    return async dispatch => {
        if(ID.ACCT !== undefined) {
            dispatch(fetchAcctReposBegin())
            const response = await axios({
                baseURL: `${url}/accts/${ID.ACCT.ID}/repos`,
                crossDomain: true,
                timeout: 200000,
                withCredentials: true,
                credentials: 'same-origin',
                responseType: 'json',
                method: 'post',
                data: {
                    TJT: (ID.JSON !== undefined ? ID.JSON : 'FULL')
                }
            })
            if(response.data.ERR !== null) {
                if(!validateSessionError(response.data.ERR))
                    dispatch(authenticateFailure())
                dispatch(fetchAcctUsersFailure(response.data.ERR))
            }else if(response.data.RSP.REPOS !== null) {
                let acct = {
                    ...ID.ACCT,
                    REPOS: response.data.RSP.REPOS
                }
                dispatch(fetchAcctReposSuccess(acct))
            }else {
                dispatch(fetchAcctReposFailure('fetchAccountRepos.error: No Account repos found.'))
            }
        }else {
            dispatch(fetchAcctFailure('fetchAccountRepos.error: No Acct provided.'))
        }
    }
}

/**
 * fetchAccountDocumentTypes calls to the sdx server
 * for a list of the Account's available DocumentTypes.
 * 
 * @param {Object} ID {ACCT: 0, TJT: ID, UI, FULL}
 */
export const fetchAccountDocumentTypes = (ID) => {
    return async dispatch => {
        if(ID.ACCT !== undefined) {
            dispatch(fetchAcctDocTypesBegin())
            const response = await axios({
                baseURL: `${url}/ui/${ID.ACCT}/doctps/pagination`,
                crossDomain: true,
                timeout: 200000,
                withCredentials: true,
                credentials: 'same-origin',
                responseType: 'json',
                method: 'post',
                data: {
                    PAGE: ID.PAGE || 0,
                    PAGE_SIZE: ID.PAGE_SIZE || 5
                }
            })
            if(response.data.ERR !== null) {
                if(!validateSessionError(response.data.ERR)) 
                    dispatch(authenticateFailure())
                dispatch(fetchAcctDocTypesFailure())
            }else if(response.data.RSP.DOCTPS && response.data.RSP.PAGINATION) {
                dispatch(updateAccountDocTypesPaginationParams(response.data.RSP.PAGINATION))
                dispatch(fetchAcctDocTypesSuccess(response.data.RSP))
            }else
                dispatch(fetchAcctDocTypesFailure("fetchAccountDocTypes.error: No Account DocTypes found."))
        }else
            dispatch(fetchAcctDocTypesFailure("fetchAccountDocTypes.error: No Account Id Provided."))
    }
}


export const initAccount = () => {
    return async dispatch => {
        if(store.getState().acct.ACCT.ID === null || store.getState().acct.ACCT.ID === undefined) {
            dispatch(fetchAccount())
        }
    }
}