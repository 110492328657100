import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";

import {connect} from 'react-redux'

import moment from 'moment'

const UserDetails = ( userDetails ) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={userDetails.avatar}
          alt={userDetails.name}
          width="110"
        />
      </div>
      <h4 className="mb-0">{userDetails.name}</h4>
      <span className="text-muted d-block mb-2">{userDetails.role}</span>
      {/* <Button pill outline size="sm" className="mb-2">
        <i className="material-icons mr-1">person_add</i> Follow
      </Button> */}
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <strong className="text-muted d-block mb-2">
            {userDetails.userCardInfoTitle}
          </strong>
          <span>{userDetails.userCardInfoValue}</span>
          {/* <Progress
            className="progress-sm"
            value={userDetails.performanceReportValue}
          >
            <span className="progress-value">
              {userDetails.performanceReportValue}%
            </span>
          </Progress> */}
        </div>
      </ListGroupItem>
      {/* <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          {userDetails.metaTitle}
        </strong>
        <span>{userDetails.metaValue}</span>
      </ListGroupItem> */}
    </ListGroup>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  name: "Sierra Brooks",
  avatar: require("./../../images/avatars/no_img_male.png"),
  role: "Project Manager",
  userCardInfoTitle: 'Last Login',
  userCardInfoValue: '',
  performanceReportTitle: "Workload",
  performanceReportValue: 74,
  metaTitle: "Description",
  metaValue: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"

};

const formatDate = millis => {
  if(millis !== null) {
    return moment(Number(millis)).format("ddd MMM Do h:mm a")
  }else {
    return 'No Date'
  } 
}

const mapStateToProps = state => ({
  name: state.auth.USR.FNM + ' ' + state.auth.USR.LNM,
  role: 'Admin User',
  userCardInfoValue: formatDate(state.auth.USR.LLOGN)
})

export default connect(mapStateToProps)(UserDetails);
