import React from "react";
import { Nav } from "shards-react";

import {connect} from "react-redux"
import {initSidebarItems} from '../../../redux/actions/layoutActions'

import SidebarNavItem from "./SidebarNavItem"
import { PropTypes } from 'prop-types'

class SidebarNavItems extends React.Component {
  
  componentWillMount() {
    this.props.dispatch(initSidebarItems())
  }

  render() {
    if(this.props.navItems !== null) {
      return (
        <div className="nav-wrapper">
          {this.props.navItems.map((nav, idx) => {
            // console.log('map, nav.items: ', nav.items)
            return (
            <div key={idx}>
              <h6 className="main-sidebar__nav-title">{nav.title}</h6>
              {typeof nav.items !== "undefined" && nav.items.length && (
                <Nav className="nav--no-borders flex-column">
                  {nav.items.map((item, idx) => {
                    // console.log('dropdown map, ', item)
                    return (
                    <SidebarNavItem key={idx} item={item} />
                  )})}
                </Nav>
              )}
            </div>
          )})}
        </div>
      )
    }
    else {
      return (
        <div>

        </div>
      )
    }
  }
}

SidebarNavItems.propTypes = {
  /**
   * The items array.
   */
  navItems: PropTypes.array
}

const mapStateToProps = state => ({
  navItems: state.layout.sidebarItems
})

export default connect(mapStateToProps)(SidebarNavItems);
