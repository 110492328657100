
import { docTypeState } from '../initialState'
import {
  ADD_DOCTYPE_BEGIN,
  ADD_DOCTYPE_SUCCESS,
  ADD_DOCTYPE_FAILURE,

  EDIT_DOCTYPE_BEGIN,
  EDIT_DOCTYPE_SUCCESS,
  EDIT_DOCTYPE_FAILURE,
  
  SET_NEW_DOCTYPE,
  UPDATE_DOCTYPE,
  RESET_DOCTYPE,
  SET_TEMPLATE_VALIDATION,
  
  FETCH_DOCTYPE_BEGIN,
  FETCH_DOCTYPE_SUCCESS,
  FETCH_DOCTYPE_FAILURE,

  FETCH_DOCTYPE_LU_BEGIN,
  FETCH_DOCTYPE_LU_SUCCESS,
  FETCH_DOCTYPE_LU_FAILURE,
  CLEAR_DOCTYPE_LU

} from '../actions/docTypeActions'


/**
 * @summary doctype reducer is responsible for handling all the DocumentType
 * related calls.
 * 
 * @param {Object} state the current value of the doctype state
 * @param {Object} action the passed in value containing the action tag as well
 * as a possible payload.
 */
export default (state = docTypeState, action) => {

  switch (action.type) {
    case ADD_DOCTYPE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ADD_DOCTYPE_SUCCESS:
      return {
        ...state,
        DOCTP: {
          ...state.DOCTP,
          ID: action.payload.ID
        },
        loading: false,
        error: null
      }
    case ADD_DOCTYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SET_NEW_DOCTYPE:
      return {
        ...state,
        loading: false,
        usingNewDocType: true,
        DOCTP: state.DOCTP_template
      }
    case UPDATE_DOCTYPE:
      return {
        ...state,
        DOCTP: action.payload
      }
    case RESET_DOCTYPE:
      return {
        ...state,
        DOCTP: {},
        usingNewDocType: false,
        existingLoaded: false
      }
    case SET_TEMPLATE_VALIDATION:
      return {
        ...state,
        valid: action.payload
      }
    case FETCH_DOCTYPE_BEGIN:
      return {
        ...state,
        loading: true
      }
    case FETCH_DOCTYPE_SUCCESS:
      return {
        ...state,
        DOCTP: action.payload,
        loading: false,
        existingLoaded: true
      }
    case FETCH_DOCTYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case EDIT_DOCTYPE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case EDIT_DOCTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        DOCTP: action.payload,
        EDITDOCTP: {}
      }
    case EDIT_DOCTYPE_FAILURE:
      return {
        ...state
      }
    case FETCH_DOCTYPE_LU_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_DOCTYPE_LU_SUCCESS:
      return {
        ...state,
        loading: false,
        DOCTPLU: action.payload,
        error: null
      }
    case FETCH_DOCTYPE_LU_FAILURE:
      return {
        ...state,
        loading: false,
        DOCTPLU: null,
        error: action.payload
      }
    case CLEAR_DOCTYPE_LU:
      return {
        ...state,
        loading: false,
        DOCTPLU: null,
        error: null
      }
    default:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
  }
}
