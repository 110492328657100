import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import url from '../connection/url'

import ReactLoading from 'react-loading'

import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle"
import RepoList from '../components/account-home/RepoList'
import UserList from '../components/account-home/UserList'
import DocumentTypeList from '../components/account-home/DocumentTypeList'

import filesize from 'filesize'
import Errors from '../views/Errors'
import moment from 'moment'

import { fetchAccount, fetchAccountUsers, fetchAccountRepos } from './../redux/actions/accountActions'
import { validateSessionError, authenticateFailure } from '../redux/actions/authActions'
import SmallStats from './../components/common/SmallStats'
import colors from '../utils/colors'




const AccountHome = (props) => {

  let { acct, loading, fetchAcct, fetchAcctUsers, fetchAcctRepos, sessionFailure } = props

  if (acct.ID === undefined && loading === false) {
    fetchAcct()
  }  else if (acct.ID !== undefined && (acct.REPOS !== null ? acct.REPOS.length > 0 ? acct.REPOS[0].TJT === 'ID' : false : false) && loading === false) {
    fetchAcctRepos({ ACCT: acct })
  }



  if (acct.STGINFO === undefined) {
    acct.STGINFO = {
      DCNT: 0,
      FCNT: 0,
      SZ: 0
    }
  }

  if (props.loading === true) {
    return (
      <div className="d-flex justify-content-center">
        <ReactLoading type={'cylon'} color={'blue'} width={'15%'} height={'15%'} />
      </div>
    )
  } else if (props.loading === false && acct.ID !== undefined) {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          {/* Page Header :: Title */}
          <PageTitle title={acct.NM} subtitle="Account Overview" className="text-sm-left mb-3" />
        </Row>
        {acct.STGINFO !== undefined
          ?
          <Row>
            <Col md="6" lg="3" className="mb-4">
              <SmallStats
                label="Total Documents"
                value={acct.STGINFO.DCNT}
                noPercent={true}
                chartData={[
                  {
                    label: 'Today',
                    fill: 'start',
                    borderWidth: 1.5,
                    backgroundColor: colors.primary.toRGBA(0.1),
                    borderColor: colors.primary.toRGBA(),
                    data: [3]
                  }
                ]}
              ></SmallStats>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <SmallStats
                label="Total Folders"
                value={acct.STGINFO.FCNT}
                noPercent={true}
                chartData={[
                  {
                    label: 'Today',
                    fill: 'start',
                    borderWidth: 1.5,
                    backgroundColor: colors.primary.toRGBA(0.1),
                    borderColor: colors.primary.toRGBA(),
                    data: [2]
                  }
                ]}
              ></SmallStats>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <SmallStats
                label="Total Storage Used"
                value={filesize(Number(acct.STGINFO.SZ))}
                noPercent={true}
                chartData={[
                  {
                    label: 'Today',
                    fill: 'start',
                    borderWidth: 1.5,
                    backgroundColor: colors.primary.toRGBA(0.1),
                    borderColor: colors.primary.toRGBA(),
                    data: [1]
                  }
                ]}
              ></SmallStats>
            </Col>
          </Row>
          :
          <div></div>}


        {/* REPO INFO */}
        <RepoList />
        {/* DOCUMENT TYPE(S) AVAILABLE ON ACCOUNT */}
        <DocumentTypeList />
        {/* USERS ON ACCOUNT */}
        <UserList />

      </Container>
    )
  } else {
    return (
      <Errors />
    )
  }
}

AccountHome.defaultProps = {
  acct: {
    STGINFO: {
      DCNT: 0,
      FCNT: 0,
      SZ: 0
    },
    USRS: []
  }
}

const mapStateToProps = state => ({
  acct: state.acct.ACCT,
  loading: state.acct.loading,
  error: state.acct.error
})

const mapDispatchToProps = dispatch => ({
  fetchAcct: () => dispatch(fetchAccount()),
  fetchAcctUsers: (ID) => dispatch(fetchAccountUsers(ID)),
  fetchAcctRepos: (ID) => dispatch(fetchAccountRepos(ID)),
  sessionFailure: () => dispatch(authenticateFailure())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountHome)