import React, { useEffect, useState } from 'react'
import { useTable, usePagination } from 'react-table'
import { connect } from 'react-redux'

import { Row,Col,Card,CardHeader,CardBody,Container,FormSelect,CardFooter,Button} from 'shards-react'


export default connect()(({ columns, data, pagination, pagination_actions, row_actions, title, clickable }) => {

  const { PAGE,PAGE_SIZE,NUM_PAGES,NUM_RESULTS } = pagination
  const { next_page,previous_page,go_to_page,show_results } = pagination_actions
  const {row_click} = row_actions || {}

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    //pagination consts
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: PAGE, pageSize: PAGE_SIZE},
      manualPagination: true,
    },
    usePagination
  )

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters">
              <Row>
                <Col className="file-manager__filters__rows d-flex justify-content-start align-items-center" md="10">
                  <h6 className="m-0 ml-3">{title}</h6>
                </Col>
                <Col className="file-manager__filters__rows d-flex justify-content-end" md="2">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    onChange={(e) => {
                      show_results(e.target.children[e.target.selectedIndex].value)
                    }}
                    defaultValue={PAGE_SIZE}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                  </FormSelect>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0" {...getTableProps()}>
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}
                      onClick={(e) => row_click(row.original.ID)}
                      className={clickable ? 'sdx-table-row-clickable' : ''}
                    >
                      {row.cells.map(cell => {
                        return <td  {...cell.getCellProps()}>{cell.render('Cell')}</td> //use cell.row.original to access obj
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          {(NUM_PAGES > 1) &&
            <CardFooter className="p-0">
              <Container fluid className="file-manager__filters border-top">
                <Row>
                  {/* previous button */}
                  <Col className="file-manager__filters__rows d-flex" md="4">
                    <Button
                      disabled={PAGE === 0}
                      theme="primary"
                      style={{ width: '7rem' }}
                      onClick={(e) => {
                        previous_page()
                      }}
                    >Previous</Button>
                  </Col>
                  {/* select page */}
                  <Col className="file-manager__filters__rows d-flex justify-content-center" md="4">
                    <span>Page</span>
                    <FormSelect
                      disabled={NUM_PAGES === 1}
                      value={PAGE+1}
                      onChange={(e) => {
                        go_to_page(e.target.children[e.target.selectedIndex].value - 1)
                      }}
                    >
                      {NUM_PAGES && buildPageOptions(NUM_PAGES).map((num, i) => (
                        <option key={i}>{num}</option>
                      ))}
                    </FormSelect>
                    <span>of {NUM_PAGES}</span>
                  </Col>
                  {/* next button */}
                  <Col className="file-manager__filters__rows d-flex justify-content-end" md="4">
                    <Button
                      theme="primary"
                      style={{ width: '7rem' }}
                      disabled={PAGE+1 === NUM_PAGES}
                      onClick={(e) => {
                        next_page()
                      }}
                    >Next</Button>
                  </Col>
                </Row>
              </Container>
            </CardFooter>}
        </Card>
      </Col>
    </Row>
  )
})

const buildPageOptions = (length) => {
  let i = 1
  let arr = []
  while(i <= length) {
    arr.push(i)
    i++
  }
  return arr
}