
import { acctState } from '../initialState'

import { RESET_APP } from '../actions/authActions'

import {
	FETCH_ACCT_BEGIN,
	FETCH_ACCT_SUCCESS,
	FETCH_ACCT_FAILURE,

	FETCH_ACCT_USERS_BEGIN,
	FETCH_ACCT_USERS_SUCCESS,
	FETCH_ACCT_USERS_FAILURE,

	FETCH_ACCT_REPOS_BEGIN,
	FETCH_ACCT_REPOS_SUCCESS,
	FETCH_ACCT_REPOS_FAILURE,

	FETCH_ACCT_DOCTPS_BEGIN,
	FETCH_ACCT_DOCTPS_SUCCESS,
	FETCH_ACCT_DOCTPS_FAILURE,
	UPDATE_ACCT_USERS_PAGINATION,
	UPDATE_ACCT_DOCTPS_PAGINATION
} from '../actions/accountActions'

export default (state = acctState, action) => {
	switch (action.type) {

		case FETCH_ACCT_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
				update: true
			}
		case FETCH_ACCT_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				ACCT: action.payload.ACCT,
				update: !state.update
			}
		case FETCH_ACCT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				update: !state.update
			}
		case FETCH_ACCT_USERS_BEGIN:
			return {
				...state,
				loading: true,
				error: null
			}
		case FETCH_ACCT_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				ACCT_USERS: action.payload
			}
		case FETCH_ACCT_USERS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload
			}
		case FETCH_ACCT_REPOS_BEGIN:
			return {
				...state,
				loading: true,
				error: null
			}
		case FETCH_ACCT_REPOS_SUCCESS:
			return {
				...state,
				ACCT: action.payload,
				loading: false,
				error: null
			}
		case FETCH_ACCT_REPOS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload
			}
		case RESET_APP:
			return {
				acctState
			}
		case FETCH_ACCT_DOCTPS_BEGIN:
			return {
				...state,
				docTypesLoading: true
			}
		case FETCH_ACCT_DOCTPS_SUCCESS:
			return {
				...state,
				ACCT_DOCTPS: action.payload,
				docTypesLoading: false
			}
		case FETCH_ACCT_DOCTPS_FAILURE:
			return {
				...state,
				docTypesLoading: false,
				docTypesError: action.payload
			}
		case UPDATE_ACCT_USERS_PAGINATION:
			return {
				...state,
				ACCT_USERS_PAGINATION: {
					...state.ACCT_USERS_PAGINATION || {},
					...action.payload
				}
			}
		case UPDATE_ACCT_DOCTPS_PAGINATION:
			return {
				...state,
				ACCT_DOCTPS_PAGINATION: {
					...state.DOCTPS_PAGINATION || {},
					...action.payload
				}
			}
		default:
			return {
				...state
			}
	}
}