import React, { useEffect } from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import UploadItems from './UploadItems'
import { connect } from 'react-redux'
import { toggleNotificationDropdown } from '../../../../redux/actions/layoutActions'

const Notifications = (props) => {

  const {toggleNotifications,showNotifications} = props

  const ref = React.createRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => {
      document.removeEventListener('mousedown', handleClick, false)
    }
  })

  const handleClick = (e) => {
    if(!ref.current.contains(e.target) && showNotifications)
    toggleNotifications()
  }

  return (
    <div ref={ref}>
      <NavItem className="border-right dropdown notifications">
        <NavLink
          className="nav-link-icon text-center"
          onClick={toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            {/* <Badge pill theme="danger">
              
            </Badge> */}
          </div>
        </NavLink>
        <Collapse
          open={showNotifications}
          className="dropdown-menu dropdown-menu-small"
        >
          <UploadItems />
          <DropdownItem className="notification__all text-center">
            View all Notifications
          </DropdownItem>
    
        </Collapse>
      </NavItem>
    </div>
  )
}

const mapStateToProps = state => ({
  showNotifications: state.layout.showNotifications
})

const mapDispatchToProps = dispatch => ({
  toggleNotifications: () => dispatch(toggleNotificationDropdown())
})

export default connect(mapStateToProps,mapDispatchToProps)(Notifications)
