import React from 'react'
import { connect } from 'react-redux'
import { FormInput,FormCheckbox,Card,CardHeader,CardBody,Col,Form,Row,Button } from 'shards-react'
import PropTypes from 'prop-types'
import { updateTemplate } from '../../redux/actions/docTypeActions'
import FieldSelectionDropdown from './FieldSelectionDropdown'


const TemplateFields = (props) => {

  
  const {colSize,template,update} = props

  
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Template Fields</h6>
      </CardHeader>
      <CardBody>
        <Col>
          <Form>
            <Row form>
              {/* STRING FIELDS */}
              <Col lg="6">
                {/* FIELD 0 NAME/REQUIRED */}
                <Col lg={colSize}>
                  <Row form>
                    <Col lg="6">
                      <Row>
                      <label htmlFor="dtfield0">Field 1 Name (text)</label>
                      <FormCheckbox 
                        className="ml-3"
                        small
                        disabled={template.FLD0 === undefined}
                        checked={(template.FLD0 !== null && template.FLD0 !== undefined) && template.FLD0.RQD.toString() === 'true'}
                        onClick={() => update({FLD0: {...template.FLD0,RQD:!(template.FLD0.RQD.toString() === 'true')}})}
                      >Required Field</FormCheckbox>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <label>Field 1 Restricted Values</label>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg="6">
                      <FormInput
                        id="dtfield0"
                        placeholder="Field 1"
                        required={(template.FLD0 !== null && template.FLD0 !== undefined) && template.FLD0.RQD.toString() === 'true'}
                        value={(template.FLD0 !== null && template.FLD0 !== undefined) ? template.FLD0.NM : ''}
                        onChange={(e) => {
                          if(template.FLD0 === undefined || template.FLD0 === null) 
                            update({FLD0: {NM:e.target.value, RQD: 'false', SLNS: {OPTS:[]}}})
                          else
                            update({FLD0: {...template.FLD0,NM:e.target.value}})}
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <FieldSelectionDropdown disabled={template.FLD0 === undefined || template.FLD0 === null} FLD={template.FLD0} tag="FLD0" />
                    </Col>
                  </Row>
                </Col>
                {/* FIELD 1 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                    <Col lg="6">
                      <Row>
                        <label htmlFor="dtfield1">Field 2 Name (text)</label>
                        <FormCheckbox 
                          className="ml-3"
                          small
                          disabled={template.FLD1 === undefined}
                          checked={(template.FLD1 !== null && template.FLD1 !== undefined) && template.FLD1.RQD.toString() === 'true'}
                          onClick={() => update({FLD1: {...template.FLD1,RQD:!(template.FLD1.RQD.toString() === 'true')}})}
                        >Required Field</FormCheckbox>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <label>Field 2 Restricted Values</label>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg="6">
                      <FormInput
                        id="dtfield1"
                        placeholder="Field 2"
                        required={(template.FLD1 !== null && template.FLD1 !== undefined) && template.FLD1.RQD.toString() === 'true'}
                        value={(template.FLD1 !== null && template.FLD1 !== undefined) ? template.FLD1.NM : ''}
                        onChange={(e) => {
                          if(template.FLD1 === undefined || template.FLD1 === null) 
                            update({FLD1: {NM:e.target.value,RQD:'false',SLNS:{OPTS:[]}}})
                          else
                            update({FLD1: {...template.FLD1,NM:e.target.value}})
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <FieldSelectionDropdown disabled={template.FLD1 === undefined || template.FLD1 === null} FLD={template.FLD1} tag="FLD1" />
                    </Col>
                  </Row>
                </Col>
                {/* FIELD 2 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                    <Col lg="6">
                      <Row>
                        <label htmlFor="dtfield2">Field 3 Name (text)</label>
                        <FormCheckbox 
                          className="ml-3"
                          small
                          disabled={template.FLD2 === undefined}
                          checked={(template.FLD2 !== null && template.FLD2 !== undefined) && template.FLD2.RQD.toString() === 'true'}
                          onClick={() => update({FLD2: {...template.FLD2 ,RQD:!(template.FLD2.RQD.toString() === 'true')}})}
                        >Required Field</FormCheckbox>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <label>Field 3 Restricted Values</label>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg="6">
                      <FormInput
                        id="dtfield2"
                        placeholder="Field 3"
                        required={(template.FLD2 !== null && template.FLD2 !== undefined) && template.FLD2.RQD.toString() === 'true'}
                        value={(template.FLD2 !== null && template.FLD2 !== undefined) ? template.FLD2.NM : ''}
                        onChange={(e) => {
                          if(template.FLD2 === undefined || template.FLD2 === null)
                            update({FLD2: {NM:e.target.value,RQD:'false',SLNS:{OPTS:[]}}})
                          else {
                            if(e.target.value.length > 0)
                              update({FLD2: {...template.FLD2,NM:e.target.value}})
                            else
                              update({FLD2: undefined})
                          }
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <FieldSelectionDropdown disabled={template.FLD2 === undefined || template.FLD2 === null} FLD={template.FLD2} tag="FLD2" />
                    </Col>
                  </Row>
                </Col>
                {/* FIELD 3 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                    <Col lg="6">
                      <Row>
                        <label htmlFor="dtfield3">Field 4 Name (text)</label>
                        <FormCheckbox 
                          className="ml-3"
                          small
                          disabled={template.FLD3 === undefined}
                          checked={(template.FLD3 !== null && template.FLD3 !== undefined) && template.FLD3.RQD.toString() === 'true'}
                          onClick={() => update({FLD3: {...template.FLD3,RQD:!(template.FLD3.RQD.toString() === 'true')}})}
                        >Required Field</FormCheckbox>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <label>Field 4 Restricted Values</label>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg="6">
                      <FormInput
                        id="dtfield3"
                        placeholder="Field 4"
                        required={(template.FLD3 !== null && template.FLD3 !== undefined) && template.FLD3.RQD.toString() === 'true'}
                        value={(template.FLD3 !== null && template.FLD3 !== undefined) ? template.FLD3.NM : ''}
                        onChange={(e) => {
                          if(template.FLD3 === undefined) 
                            update({FLD3: {NM:e.target.value,RQD:'false',SLNS:{OPTS:[]}}})
                          else {
                            if(e.target.value.length > 0)
                              update({FLD3: {...template.FLD3,NM:e.target.value}})
                            else
                              update({FLD3: undefined})
                          }
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <FieldSelectionDropdown disabled={template.FLD3 === undefined || template.FLD3 === null} FLD={template.FLD3} tag="FLD3" />
                    </Col>
                  </Row>
                </Col>
                {/* FIELD 4 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                    <Col lg="6">
                      <Row>
                        <label htmlFor="dtfield4">Field 5 Name (text)</label>
                        <FormCheckbox 
                          className="ml-3"
                          small
                          disabled={template.FLD4 === undefined}
                          checked={(template.FLD4 !== null && template.FLD4 !== undefined) && template.FLD4.RQD.toString() === 'true'}
                          onClick={() => update({FLD4: {...template.FLD4,RQD:!(template.FLD4.RQD.toString() === 'true')}})}
                        >Required Field</FormCheckbox>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <label>Field 5 Restricted Values</label>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg="6" className="mb-0">
                      <FormInput
                        id="dtfield4"
                        placeholder="Field 5"
                        value={(template.FLD4 !== null && template.FLD4 !== undefined) ? template.FLD4.NM : ''}
                        onChange={(e) => {
                          if(template.FLD4 === undefined)
                            update({FLD4: {NM:e.target.value,RQD:'false',SLNS:{OPTS:[]}}})
                          else {
                            if(e.target.value.length > 0)
                              update({FLD4: {...template.FLD4,NM:e.target.value}})
                            else
                              update({FLD4: undefined})
                          }
                        }}
                      />
                    </Col>
                    <Col lg="6" className="mb-0">
                      <FieldSelectionDropdown disabled={template.FLD4 === undefined || template.FLD4 === null} FLD={template.FLD4} tag="FLD4" />
                    </Col>
                  </Row>
                </Col>
              </Col>
              {/* NUMERICAL FIELDS */}
              <Col lg="6">
                {/* FIELD 5 NAME/REQUIRED */}
                <Col lg={colSize}>
                  <Row form>
                  <Col lg="3">
                    <label htmlFor="dtfield5">Field 6 Name (Number)</label>
                  </Col>
                  <Col lg="3">
                    <FormCheckbox 
                      className="ml-3"
                      small
                      disabled={template.FLD5 === undefined}
                      checked={(template.FLD5 !== null && template.FLD5 !== undefined) && template.FLD5.RQD.toString() === 'true'}
                      onClick={() => update({FLD5: {...template.FLD5,RQD:!(template.FLD5.RQD.toString() === 'true')}})}
                    >Required Field</FormCheckbox>
                  </Col>
                  </Row>
                  <Row form>
                    <FormInput
                      id="dtfield5"
                      placeholder="Field 6"
                      value={(template.FLD5 !== null && template.FLD5 !== undefined) ? template.FLD5.NM : ''}
                      onChange={(e) => {
                        if(template.FLD5 === undefined)
                          update({FLD5: {NM:e.target.value,RQD:'false'}})
                        else {
                          if(e.target.value.length > 0)
                            update({FLD5: {...template.FLD5,NM:e.target.value}})
                          else
                            update({FLD5: undefined})
                        }
                      }}
                    />
                  </Row>
                </Col>
                {/* FIELD 6 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                  <Col lg="3">
                    <label htmlFor="dtfield6">Field 7 Name (Number)</label>
                  </Col>
                  <Col lg="3">
                    <FormCheckbox 
                      className="ml-3"
                      small
                      disabled={template.FLD6 === undefined}
                      checked={(template.FLD6 !== null && template.FLD6 !== undefined) && template.FLD6.RQD.toString() === 'true'}
                      onClick={() => update({FLD6: {...template.FLD6,RQD:!(template.FLD6.RQD.toString() === 'true')}})}
                    >Required Field</FormCheckbox>
                  </Col>
                  </Row>
                  <Row form>
                    <FormInput
                      id="dtfield6"
                      placeholder="Field 7"
                      value={(template.FLD6 !== null && template.FLD6 !== undefined) ? template.FLD6.NM : ''}
                      onChange={(e) => {
                        if(template.FLD6 === undefined)
                          update({FLD6: {NM:e.target.value,RQD:'false'}})
                        else {
                          if(e.target.value.length > 0)
                            update({FLD6: {...template.FLD6,NM:e.target.value}})
                          else
                            update({FLD6: undefined})
                        }
                      }}
                    />
                  </Row>
                </Col>
                {/* FIELD 7 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                  <Col lg="3">
                    <label htmlFor="dtfield7">Field 8 Name (Number)</label>
                  </Col>
                  <Col lg="3">
                    <FormCheckbox 
                      className="ml-3"
                      small
                      disabled={template.FLD7 === undefined}
                      checked={(template.FLD7 !== null && template.FLD7 !== undefined) && template.FLD7.RQD.toString() === 'true'}
                      onClick={() => update({FLD7: {...template.FLD7,RQD:!(template.FLD7.RQD.toString() === 'true')}})}
                    >Required Field</FormCheckbox>
                  </Col>
                  </Row>
                  <Row form>
                    <FormInput
                      id="dtfield7"
                      placeholder="Field 8"
                      value={(template.FLD7 !== null && template.FLD7 !== undefined) ? template.FLD7.NM : ''}
                      onChange={(e) => {
                        if(template.FLD7 === undefined)
                          update({FLD7: {NM:e.target.value,RQD:'false'}})
                        else {
                          if(e.target.value.length > 0)
                            update({FLD7: {...template.FLD7,NM:e.target.value}})
                          else
                            update({FLD7: undefined})
                        }
                      }}
                    />
                  </Row>
                </Col>

                
                {/* FIELD 8 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                    <Col lg="3">
                      <label htmlFor="dtfield8">Field 9 Name (Date)</label>
                    </Col>
                    <Col lg="3">
                      <FormCheckbox 
                        className="ml-3"
                        small
                        disabled={template.FLD8 === undefined}
                        checked={(template.FLD8 !== null && template.FLD8 !== undefined) && template.FLD8.RQD.toString() === 'true'}
                        onClick={() => update({FLD8: {...template.FLD8,RQD:!(template.FLD8.RQD.toString() === 'true')}})}
                      >Required Field</FormCheckbox>
                    </Col>
                  </Row>
                  <Row form>
                    <FormInput
                      id="dtfield8"
                      placeholder="Field 9"
                      value={(template.FLD8 !== null && template.FLD8 !== undefined) ? template.FLD8.NM : ''}
                      onChange={(e) => {
                        if(template.FLD8 === undefined)
                          update({FLD8: {NM:e.target.value,RQD:'false'}})
                        else {
                          if(e.target.value.length > 0)
                            update({FLD8: {...template.FLD8,NM:e.target.value}})
                          else
                            update({FLD8: undefined})
                        }
                      }}
                    />
                  </Row>
                </Col>
                {/* FIELD 9 NAME/REQUIRED */}
                <Col lg={colSize} className="mt-4">
                  <Row form>
                    <Col lg="3">
                      <label htmlFor="dtfield9">Field 10 Name (Date)</label>
                    </Col>
                    <Col lg="3">
                      <FormCheckbox 
                        className="ml-3"
                        small
                        disabled={template.FLD9 === undefined}
                        checked={(template.FLD9 !== null && template.FLD9 !== undefined) && template.FLD9.RQD.toString() === 'true'}
                        onClick={() => update({FLD9: {...template.FLD9,RQD:!(template.FLD9.RQD.toString() === 'true')}})}
                      >Required Field</FormCheckbox>
                    </Col>
                  </Row>
                  <Row form>
                    <FormInput
                      id="dtfield9"
                      placeholder="Field 10"
                      value={(template.FLD9 !== null && template.FLD9 !== undefined) ? template.FLD9.NM : ''}
                      onChange={(e) => {
                        if(template.FLD9 === undefined)
                          update({FLD9: {NM:e.target.value,RQD:'false'}})
                        else {
                          if(e.target.value.length > 0)
                            update({FLD9: {...template.FLD9,NM:e.target.value}})
                          else
                            update({FLD9: undefined})
                        }
                      }}
                    />
                  </Row>
                </Col>
              </Col>
            </Row>
          </Form>
        </Col>
      </CardBody>
    </Card>
  )
}

TemplateFields.propTypes = {

  colSize: PropTypes.string,
}

TemplateFields.defaultProps = {
  colSize: '12',
  template: {
    FLD0: {
      NM: '',
      RQD: false
    },
    FLD1: {
        NM: '',
        RQD: false
    },
    FLD2: {
        NM: '',
        RQD: false
    },
    FLD3: {
        NM: '',
        RQD: false
    },
    FLD4: {
        NM: '',
        RQD: false
    },
    FLD5: {
        NM: '',
        RQD: false
    },
    FLD6: {
        NM: '',
        RQD: false
    },
    FLD7: {
        NM: '',
        RQD: false
    },
    FLD8: {
        NM: '',
        RQD: false
    },
    FLD9: {
        NM: '',
        RQD: false
    }
  }
}

const mapStateToProps = (state) => ({
  template: state.doctype.DOCTP
})

const mapDispatchToProps = (dispatch) => ({
  update: (obj) => dispatch(updateTemplate(obj))
})

export default connect(mapStateToProps,mapDispatchToProps)(TemplateFields)