import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse
} from "shards-react";

import {connect} from 'react-redux'
import {toggleSidebarDropdown} from '../../../redux/actions/layoutActions'


const SidebarNavItem = (props) => {
  const { item } = props;
  const hasSubItems = item.items && item.items.length;
  return (
    <NavItem style={{ position: "relative" }}>
      <NavLink
        className={hasSubItems && "dropdown-toggle"}
        tag={hasSubItems ? "a" : RouteNavLink}
        to={hasSubItems ? "#" : item.to}
        onClick={() => props.toggle(item)}
      >
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
      {hasSubItems && (
        <Collapse tag={DropdownMenu} small open={item.open} style={{ top: 0 }}>
          {item.items.map((subItem, idx) => (
            <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to}>
              {/* {subItem.htmlBefore && (
                <div 
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{__html: subItem.htmlBefore}}
                />
              )} */}
              <span>{subItem.title}</span>
            </DropdownItem>
          ))}
        </Collapse>
      )}
    </NavItem>
  );
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

const mapStateToProps = state => ({
  update: state.layout.update
})

const mapDispatchToProps = dispatch => ({
  toggle: item => dispatch(toggleSidebarDropdown(item))
})

export default connect(mapStateToProps,mapDispatchToProps)(SidebarNavItem);
