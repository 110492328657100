import React from 'react'
import classNames from 'classnames'
import { InputGroup,FormInput} from 'shards-react'
import { connect } from 'react-redux'
import { updateTemplate } from '../../redux/actions/docTypeActions'


const ExpirationLifespan = (props) => {

  const { className,template,update } = props
  const classes = classNames(className, 'd-flex','my-auto')


  return (
    <InputGroup className={classes}>
      <FormInput
        type="number"
        id="elyr"
        placeholder="years"
        onChange={(e) => update({EXPSAFTR: {...template.EXPSAFTR,YR:e.target.value}})}
        defaultValue={(template.EXPSAFTR !== null && template.EXPSAFTR !== undefined) && template.EXPSAFTR.YR}
        disabled={template.EXPS !== undefined ? template.EXPS.toString() === 'false' : true}
      />
      <FormInput
        type="number"
        id="elmth"
        placeholder="months"
        onChange={(e) => update({EXPSAFTR: {...template.EXPSAFTR,MNTH:e.target.value}})}
        defaultValue={(template.EXPSAFTR !== null && template.EXPSAFTR !== undefined) && template.EXPSAFTR.MNTH}
        disabled={template.EXPS !== undefined ? template.EXPS.toString() === 'false' : true}
      />
      <FormInput
        type="number"
        id="eldays"
        placeholder="days"
        onChange={(e) => update({EXPSAFTR: {...template.EXPSAFTR,DAY:e.target.value}})}
        defaultValue={(template.EXPSAFTR !== null && template.EXPSAFTR !== undefined) && template.EXPS.DAY}
        disabled={template.EXPS !== undefined ? template.EXPS.toString() === 'false' : true}
      />
    </InputGroup>
  )
}

const mapDispatchToProps = (dispatch) => ({
  update: (obj) => dispatch(updateTemplate(obj))
})

const mapStateToProps = (state) => ({
  template: state.doctype.DOCTP
})

export default connect(mapStateToProps,mapDispatchToProps)(ExpirationLifespan)