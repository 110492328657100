import React from 'react'
import { InputGroup,DatePicker,InputGroupAddon,InputGroupText,Button } from 'shards-react'
import classNames from 'classnames'

/**
 * 
 * @param {Component} SdxRangeDatePicker Custom RangeDatePicker to allow external control of date value and onChange method 
 */
const SdxRangeDatePicker = (props) => {
 
  const {startValue,endValue,onChange,showStart,showTimePicker,className,onClear} = props
  const classes = classNames(className, "d-flex", "my-auto", "date-range")
  
  return (
    <InputGroup className={classes}>
        {showStart && (
          <DatePicker
            size="sm"
            selected={startValue}
            onChange={onChange}
            placeholderText="Start Date"
            dropdownMode="select"
            className="text-center"
            showTimeSelect={showTimePicker}
            disabled={props.disabled}

          />
        )}
        <DatePicker
          size="sm"
          selected={endValue}
          onChange={onChange}
          placeholderText="End Date"
          dropdownMode="select"
          className="text-center"
          showTimeSelect={showTimePicker}
          disabled={props.disabled}
        />
        <InputGroupAddon type="append">
          <InputGroupText>
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>
        </InputGroupAddon>
        {endValue && (
          <InputGroupAddon type="append">
            <InputGroupText>
            <i onClick={onClear}
            className="material-icons sdx-input-group-clickable">clear</i>
            </InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
  )
}

SdxRangeDatePicker.defaultProps = {
  onChange: () => {},
  startValue: undefined,
  endValue: undefined,
  showStart: false,
  showTimePicker: false,
  disabled: false,
  onClear: () => {}
}

export default SdxRangeDatePicker