import url from '../../connection/url'
import axios from '../../connection/axiosHandler'
import { validateSessionError,authenticateFailure } from './authActions'
import store from '../store'


export const ADD_DOCTYPE_BEGIN = 'ADD_DOCTYPE_BEGIN'
export const ADD_DOCTYPE_SUCCESS = 'ADD_DOCTYPE_SUCCESS'
export const ADD_DOCTYPE_FAILURE = 'ADD_DOCTYPE_FAILURE'

export const EDIT_DOCTYPE_BEGIN = "EDIT_DOCTYPE_BEGIN"
export const EDIT_DOCTYPE_SUCCESS  = "EDIT_DOCTYPE_SUCCESS"
export const EDIT_DOCTYPE_FAILURE = "EDIT_DOCTYPE_FAILURE"

export const FETCH_DOCTYPES_BEGIN = 'FETCH_DOCTYPES_BEGIN'
export const FETCH_DOCTYPES_SUCCESS = 'FETCH_DOCTYPES_SUCCESS'
export const FETCH_DOCTYPES_FAILURE = 'FETCH_DOCTYPES_FAILURE'

export const FETCH_DOCTYPE_BEGIN = 'FETCH_DOCTYPE_BEGIN'
export const FETCH_DOCTYPE_SUCCESS = 'FETCH_DOCTYPE_SUCCESS'
export const FETCH_DOCTYPE_FAILURE = 'FETCH_DOCTYPE_FAILURE'

export const FETCH_DOCTYPE_LU_BEGIN = "FETCH_DOCTYPE_LU_BEGIN"
export const FETCH_DOCTYPE_LU_SUCCESS = "FETCH_DOCTYPE_LU_SUCCESS"
export const FETCH_DOCTYPE_LU_FAILURE = "FETCH_DOCTYPE_LU_FAILURE"

export const CLEAR_DOCTYPE_LU = "CLEAR_DOCTYPE_LU"

export const SET_NEW_DOCTYPE = 'SET_NEW_DOCTYPE'

export const UPDATE_DOCTYPE = 'UPDATE_DOCTYPE'

export const RESET_DOCTYPE = 'RESET_DOCTYPE'

export const SET_TEMPLATE_VALIDATION = 'SET_TEMPLATE_VALIDATION'

export const addDoctypeBegin = () => ({
  type: ADD_DOCTYPE_BEGIN
})

export const addDoctypeSuccess = (doctype) => ({
  type: ADD_DOCTYPE_SUCCESS,
  payload: doctype
})

export const addDoctypeFailure = (ERR) => ({
  type: ADD_DOCTYPE_FAILURE,
  payload: ERR
})

export const editDocTypeBegin = () => ({
  type: EDIT_DOCTYPE_BEGIN
})

export const editDocTypeSuccess = (doctype) => ({
  type: EDIT_DOCTYPE_SUCCESS,
  payload: doctype
})

export const editDocTypeFailure = (ERR) => ({
  type: EDIT_DOCTYPE_FAILURE,
  payload: ERR
})

export const setNewDocType = () => ({
  type: SET_NEW_DOCTYPE
})

export const updateDocType = (doctp) => ({
  type: UPDATE_DOCTYPE,
  payload: doctp
})

export const resetDocType = () => ({
  type: RESET_DOCTYPE
})

export const setTemplateValid = (valid) => ({
  type: SET_TEMPLATE_VALIDATION,
  payload: valid
})

export const fetchDocTypeBegin = () => ({
  type: FETCH_DOCTYPE_BEGIN
})

export const fetchDocTypeSuccess = (doctp) => ({
  type: FETCH_DOCTYPE_SUCCESS,
  payload: doctp
})

export const fetchDocTypeFailure = (ERR) => ({
  type: FETCH_DOCTYPE_FAILURE,
  payload: ERR
})

export const fetchDocTypeLUBegin = () => ({
  type: FETCH_DOCTYPE_LU_BEGIN
})

export const fetchDocTypeLUSuccess = (LU) => ({
  type: FETCH_DOCTYPE_LU_SUCCESS,
  payload: LU
})

export const fetchDocTypeLUFailure = (ERR) => ({
  type: FETCH_DOCTYPE_LU_FAILURE,
  payload: ERR
})

export const clearDocTypeLU = () => ({
  type: CLEAR_DOCTYPE_LU
})

/**
 * @summary Adds a DocumentType under the provided Account.
 * @param {Object} ID Contains necessary param Ids and JSON
 */
export const addDoctype = (ID) => {
  return async dispatch => {
    if(ID.ACCT !== null && ID.JSON !== null) {
      dispatch(addDoctypeBegin())
      const response = await axios({
        baseURL: `${url}/accts/${ID.ACCT}/doctps/add`,
        crossDomain: true,
        timeout: 200000,
        withCredentials: true,
        credentials: 'same-origin',
        responseType: 'json',
        method: 'post',
        data: ID.JSON
      })
      if(response.data.ERR !== null) {
        if(!validateSessionError(response.data.ERR)) 
          dispatch(authenticateFailure())
        dispatch(addDoctypeFailure(response.data.ERR))
      }else if(response.data.RSP !== null) {
        if(response.data.RSP.DOCTP.ID !== undefined && response.data.RSP.DOCTP.ID !== null) {
          dispatch(addDoctypeSuccess(response.data.RSP.DOCTP))
        }
      }else {
        dispatch(addDoctypeFailure('addDoctype: no DocType returned.'))
      }
    }else {
      dispatch(addDoctypeFailure('addDoctype: Account Id could not be located.'))
    }
  }
}

/**
 * fetchDocType calls to the sdx server for the matching 
 * DocumentType Id owned by the provided Acct Id
 * 
 * @param {Object} ID  {ACCT: 0, DOCTP: 0}
 */
export const fetchDocType = (ID) => {
  return async dispatch => {
    if(ID.ACCT !== null && ID.DOCTP !== null) {
      dispatch(fetchDocTypeBegin())
      const response = await axios({
        baseURL: `${url}/accts/${ID.ACCT}/doctps/${ID.DOCTP}`,
        crossDomain: true,
        timeout: 200000,
        withCredentials: true,
        credentials: 'same-origin',
        responseType: 'json',
        method: 'get'
      })
      if(response.data.ERR !== null) {
        if(!validateSessionError(response.data.ERR)) 
          dispatch(authenticateFailure())
        dispatch(fetchDocTypeFailure(response.data.ERR))
      }else if(response.data.RSP !== null) {
        dispatch(fetchDocTypeSuccess(response.data.RSP.DOCTP))
      }else
        dispatch(fetchDocTypeFailure('fetchDocType: No docType returned.'))
    }else
      dispatch(fetchDocTypeFailure('fetchDocType: Account Id and/or DocumentType Id could not be located.'))
  }
}

/**
 * fetchDocTypeLU calls to the sdx server for the lastUpdated
 * of the DocumentType specified by the provided DocumentType Id
 * owned by the provided Account Id
 * 
 * @param {*} ID {ACCT: 0, DOCTP: 0}
 */
export const fetchDocTypeLU = (ID) => {
  return async dispatch => {
    if(ID.ACCT && ID.DOCTP) {
      dispatch(fetchDocTypeLUBegin())
      const response = await axios({
        baseURL: `${url}/accts/${ID.ACCT}/doctps/${ID.DOCTP}/lu`,
        crossDomain: true,
        timeout: 200000,
        withCredentials: true,
        credentials: 'same-origin',
        responseType: 'json',
        method: 'get'
      })
      if(response.data.ERR !== null) {
        if(!validateSessionError(response.data.ERR))
          dispatch(authenticateFailure())
        dispatch(fetchDocTypeLUFailure())
      }else if(response.data.RSP !== null)
        dispatch(fetchDocTypeLUSuccess(response.data.RSP.LU))
      else
        dispatch(fetchDocTypeLUFailure('fetchDocTypeLU: No DocType LU returned.'))
    }else
      dispatch(fetchDocTypeLUFailure('fetchDocTypeLU: Account Id and/or DocumentType Id could not be located.'))
  }
}

/**
 * editDocType calls to the sdx server to perform an update
 * on the provided DocumentType object. The object is formatted
 * in order to conform to the {VAL/DEL} updating structure for 
 * each field if applicable.
 * 
 * @param {Object} ID {ACCT: 0, DOCTP: {...}}
 */
export const editDocType = (ID) => {
  return async dispatch => {
    if(ID.ACCT !== undefined && ID.DOCTP !== undefined) {
      dispatch(editDocTypeBegin())
      const json = formatDoctypeForUpdate(ID.DOCTP)
      console.log('doctp before update: ', ID.DOCTP)
      const response = await axios({
        baseURL: `${url}/accts/${ID.ACCT}/doctps/${ID.DOCTP.ID}/update`,
        crossDomain: true,
        timeout: 200000,
        withCredentials: true,
        credentials: 'same-origin',
        responseType: 'json',
        method: 'post',
        data: json
      })
      if(response.data.ERR !== null) {
        if(validateSessionError(response.data.ERR)) 
          dispatch(authenticateFailure())
        dispatch(editDocTypeFailure(response.data.ERR))
      }else if(response.data.RSP !== null) {
        dispatch(editDocTypeSuccess(response.data.RSP.DOCTP))
        dispatch(fetchDocTypeLUSuccess(response.data.RSP.DOCTP.LU))
      }else {
        dispatch(editDocTypeFailure('editDocType: No DocType returned.'))
      }
    }else
      dispatch(editDocTypeFailure('editDocType: Account Id and/or DocumentType Id could not be located.'))
  }
}

/**
 * updateTemplate takes in an object containing the field(s) that 
 * are needing to be updated on the docType object, of a singular depth, with all
 * subsequent depths provided under the first level provided.
 * 
 * @param {Object} doctp {NM: 'new value', FLD0: {NM: 'new value', RQD: false, SMK: 'domerwef'}}
 */
export const updateTemplate = (doctp) => {
  return async dispatch => {
    let _doctp = store.getState().doctype.DOCTP
    if(Object.keys(doctp) !== null ? Object.keys(doctp).length > 0 : false) {
      for(let k in Object.keys(doctp)) {
        _doctp = {
          ..._doctp,
          [Object.keys(doctp)[k]]: doctp[Object.keys(doctp)[k]]
        }
      }
      dispatch(setTemplateValid(isValid(_doctp)))
      dispatch(updateDocType(_doctp))
    }else {
      console.log('updateTemplate: no keys found in object')
    }
  }
}

/**
 * isValid checks for the required fields to allow a new documentType to
 * be updated and/or added to the sdx system.
 * 
 * @param {Object} doctp DocumentType Object
 */
const isValid = (doctp) => {
  // console.log('isValid, doctp: ', doctp)
  if(doctp !== null && doctp.TJT !== 'ID') {
    if(doctp.NM.length === 0) {
      return false
    }
    // FIELD 0 REQUIREMENTS
    if(doctp.FLD0 !== undefined) {
      if((doctp.FLD0.NM.length == 0 && (doctp.FLD0.RQD === 'true' || doctp.FLD0.RQD === true))) 
        return false
    }
    // FIELD 1 REQUIREMENTS
    if(doctp.FLD1 !== undefined) {
      if(doctp.FLD1.NM.length === 0 && (doctp.FLD1.RQD === 'true' || doctp.FLD1.RQD === true))
        return false
    }
    // FIELD 2 REQUIREMENTS
    if(doctp.FLD2 !== undefined && doctp.FLD2 !== null) {
      if(doctp.FLD2.NM.length === 0 && (doctp.FLD1.RQD === 'true' || doctp.FLD2.RQD === true))
        return false
    }
    // FIELD 3 REQUIREMENTS
    if(doctp.FLD3 !== undefined) {
      if(doctp.FLD3.NM.length === 0 && (doctp.FLD3.RQD === 'true' || doctp.FLD3.RQD === true))
        return false
    }
    // FIELD 4 REQUIREMENTS
    if(doctp.FLD4 !== undefined) {
      if(doctp.FLD4.NM.length === 0 && (doctp.FLD4.RQD === 'true' || doctp.FLD4.RQD === true)) {
        return false
      }
    }
    // FIELD 5 REQUIREMENTS
    if(doctp.FLD5 !== undefined) {
      if(doctp.FLD5.NM.length === 0 && (doctp.FLD5.RQD === 'true' || doctp.FLD5.RQD === true)) {
        return false
      }
    }
    // FIELD 6 REQUIREMENTS
    if(doctp.FLD6 !== undefined) {
      if(doctp.FLD6.NM.length === 0 && (doctp.FLD5.RQD === 'true' || doctp.FLD6.RQD === true)) {
        return false
      }
    }
    // FIELD 7 REQUIREMENTS
    if(doctp.FLD7 !== undefined) {
      if(doctp.FLD7.NM.length === 0 && (doctp.FLD7.RQD === 'true' || doctp.FLD7.RQD === true)) {
        return false
      }
    }
    // FIELD 8 REQUIREMENTS
    if(doctp.FLD8 !== undefined) {
      if(doctp.FLD8.NM.length === 0 && (doctp.FLD8.RQD === 'true' || doctp.FLD8.RQD === true)) {
        return false
      }
    }
    // FIELD 9 REQUIREMENTS
    if(doctp.FLD9 !== undefined) {
      if(doctp.FLD9.NM.length === 0 && (doctp.FLD9.RQD === 'true' || doctp.FLD9.RQD === true)) {
        return false
      }
    }

    if(doctp.EXPS === 'true' || doctp.EXPS === true) {
      if(doctp.EXPSON === null && doctp.EXPSAFTER === null) {
        return false
      }
    }
    return true
  } else if(doctp.ID !== undefined && doctp.ID !== null) 
      return true
    else
      return false
}

/**
 * formatDoctypeForUpdate takes the DOCTP object and converts it 
 * into the expected format for the sdx-server to perform an update
 * on the provided object.
 * 
 * @param {Object} DOCTP The DocumentType object
 */
const formatDoctypeForUpdate = (DOCTP) => {
  if(DOCTP !== undefined && DOCTP !== null) {
    return ({
      ID: DOCTP.ID,
      NM: { VAL: DOCTP.NM },
      ACCT: { ID: DOCTP.ACCT.ID },
      AUDIT: { VAL: DOCTP.AUDIT },
      CMP: { VAL: DOCTP.CMP },
      ENCD: { VAL: DOCTP.ENCD },
      EXPS: { VAL: DOCTP.EXPS },
      EXPSON: (DOCTP.EXPSON !== null ? { VAL: DOCTP.EXPSAFTR } :  { DEL: true }),
      EXPSAFTR: (DOCTP.EXPSAFTR !== null ? {
        YR: (DOCTP.EXPSAFTR.YR !== undefined ? { VAL: DOCTP.EXPSAFTR.YR } : { DEL: true }),
        MNTH: (DOCTP.EXPSAFTR.MNTH !== undefined ? { VAL: DOCTP.EXPSAFTR.MNTH } : { DEL: true }),
        DAY: (DOCTP.EXPSAFTR.DAY !== undefined ? { VAL: DOCTP.EXPSAFTR.DAY } : { DEL: true })
      } : { DEL: true }),
      FLD0: (DOCTP.FLD0 !== undefined && DOCTP.FLD0 !== null ? {
        NM: (DOCTP.FLD0.NM.length > 0 ? { VAL: DOCTP.FLD0.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD0.RQD === 'true' || DOCTP.FLD0.RQD === true },
        SLNS: ((DOCTP.FLD0.SLNS !== undefined && DOCTP.FLD0.SLNS !== null) ? DOCTP.FLD0.SLNS : null)
      } : null),
      FLD1: (DOCTP.FLD1 !== undefined && DOCTP.FLD1 !== null ? {
        NM: (DOCTP.FLD1.NM.length > 0 ? { VAL: DOCTP.FLD1.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD1.RQD === 'true' || DOCTP.FLD1.RQD === true },
        SLNS: ((DOCTP.FLD1.SLNS !== undefined && DOCTP.FLD1.SLNS !== null) ? DOCTP.FLD1.SLNS : null)
      } : null),
      FLD2: (DOCTP.FLD2 !== undefined && DOCTP.FLD2 !== null ? {
        NM: (DOCTP.FLD2.NM.length > 0 ? { VAL: DOCTP.FLD2.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD2.RQD === 'true' || DOCTP.FLD2.RQD === true },
        SLNS: ((DOCTP.FLD2.SLNS !== undefined && DOCTP.FLD2.SLNS !== null) ? DOCTP.FLD2.SLNS : null)
      } : null),
      FLD3: (DOCTP.FLD3 !== undefined && DOCTP.FLD3 !== null ? {
        NM: (DOCTP.FLD3.NM.length > 0 ? { VAL: DOCTP.FLD3.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD3.RQD === 'true' || DOCTP.FLD3.RQD === true },
        SLNS: ((DOCTP.FLD3.SLNS !== undefined && DOCTP.FLD3.SLNS !== null) ? DOCTP.FLD3.SLNS : null)
      } : null),
      FLD4: (DOCTP.FLD4 !== undefined && DOCTP.FLD4 !== null ? {
        NM: (DOCTP.FLD4.NM.length > 0 ? { VAL: DOCTP.FLD4.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD4.RQD === 'true' || DOCTP.FLD4.RQD === true },
        SLNS: ((DOCTP.FLD4.SLNS !== undefined && DOCTP.FLD4.SLNS !== null) ? DOCTP.FLD4.SLNS : null)
      } : null),
      FLD5: (DOCTP.FLD5 !== undefined && DOCTP.FLD5 !== null ? {
        NM: (DOCTP.FLD5.NM.length > 0 ? { VAL: DOCTP.FLD5.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD5.RQD === 'true' || DOCTP.FLD5.RQD === true }
      } : null),    
      FLD6: (DOCTP.FLD6 !== undefined && DOCTP.FLD6 !== null ? {
        NM: (DOCTP.FLD6.NM.length > 0 ? { VAL: DOCTP.FLD6.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD6.RQD === 'true' || DOCTP.FLD6.RQD === true }
      } : null),
      FLD7: (DOCTP.FLD7 !== undefined && DOCTP.FLD7 !== null ? {
        NM: (DOCTP.FLD7.NM.length > 0 ? { VAL: DOCTP.FLD7.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD7.RQD === 'true' || DOCTP.FLD7.RQD === true }
      } : null),
      FLD8: (DOCTP.FLD8 !== undefined && DOCTP.FLD8 !== null ? {
        NM: (DOCTP.FLD8.NM.length > 0 ? { VAL: DOCTP.FLD8.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD8.RQD === 'true' || DOCTP.FLD8.RQD === true }
      } : null),
      FLD9: (DOCTP.FLD9 !== undefined && DOCTP.FLD9 !== null ? {
        NM: (DOCTP.FLD9.NM.length > 0 ? { VAL: DOCTP.FLD9.NM } : { DEL: true }),
        RQD: { VAL: DOCTP.FLD9.RQD === 'true' || DOCTP.FLD9.RQD === true }
      } : null)
    })
  }
}