import React from "react";
import { connect } from 'react-redux'
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";

const NavbarSearch = (props) => (
  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
    <InputGroup seamless className="ml-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        className="navbar-search"
        placeholder="Search for something..."
        onKeyUp={props.handler}
        onPaste={(e) => {
          e.persist()
          setTimeout(() => {
            console.log('e: ', e.target.value)
            props.handler(e)
          },500)
        }}
        onKeyPress={(e) => {
          if(e.charCode === 13) {
            e.preventDefault()
            props.handler(e)
          }
        }}
      />
    </InputGroup>
  </Form>
);

const mapStateToProps = state => ({
  handler: state.layout.navbarSearchHandler
})

export default connect(mapStateToProps)(NavbarSearch)