import React from 'react'
import { connect } from 'react-redux'
import { Row,Col,Card,CardHeader,CardBody } from 'shards-react'

import filesize from 'filesize'

const RepoList = (props) => {

  const {acct} = props

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Repositories</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                                        </th>
                  <th scope="col" className="border-0">
                    Name
                                        </th>
                  <th scope="col" className="border-0">
                    # Documents
                                        </th>
                  <th scope="col" className="border-0">
                    # Folders
                                        </th>
                  <th scope="col" className="border-0">
                    Storage Used
                                        </th>
                  <th scope="col" className="border-0">
                    compressed?
                                        </th>
                  <th scope="col" className="border-0">
                    full?
                                        </th>
                </tr>
              </thead>
              <tbody>
                {acct.REPOS.map((repo, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{repo.NM}</td>
                    <td>
                      {repo.STGINFO !== undefined ? repo.STGINFO.DCNT : 0}
                    </td>
                    <td>
                      {repo.STGINFO !== undefined ? repo.STGINFO.FCNT : 0}
                    </td>
                    <td>
                      {repo.STGINFO !== undefined ? filesize(Number(repo.STGINFO.SZ)) : filesize(Number(0))}
                    </td>
                    <td>
                      <span
                        style={{ color: (repo.CMP === 'true' ? 'green' : 'red') }}
                        className="material-icons">{repo.CMP === 'true' ? 'check' : 'close'}</span>
                    </td>
                    <td>
                      <span
                        style={{ color: (repo.FULL === 'true' ? 'green' : 'red') }}
                        className="material-icons">{repo.FULL === 'true' ? 'check' : 'close'}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  acct: state.acct.ACCT
})

export default connect(mapStateToProps)(RepoList)