import React from 'react'
import { connect } from 'react-redux'
import { Card,CardHeader,CardBody,Col,Row,Form,Collapse } from 'shards-react'
import ExpirationLifespan from './ExpirationLifespan'
import { updateTemplate } from '../../redux/actions/docTypeActions'
import SdxRangeDatePicker from '../common/SdxRangeDatePicker'


const ExpirationSettings = (props) => {

  const {template,update} = props
  return (
    <Collapse open={true}>
      <Card small  className="mb-4">
        <CardHeader  className="border-bottom">
          <h6 className="m-0">Expiration Configuration</h6>
        </CardHeader>
        <CardBody>
          <Col>
            <Form>
              <Row form>
                {/* EXPIRATION DATE PICKER */}
                <Col lg="6">
                  <Row form>
                    <label htmlFor="dtexpr">Expiration Date</label>
                    <SdxRangeDatePicker 
                      disabled={template.EXPS !== undefined ? template.EXPS.toString() === 'false' : true}
                      endValue={template.EXPSON !== null && template.EXPSON !== undefined ? new Date(Number(template.EXPSON)) : undefined}
                      onChange={(value) => update({EXPSON: value.getTime()})}
                      onClear={() => update({EXPSON: undefined})}
                      />
                  </Row>
                </Col>
                {/* LIFESPAN TIME PICKER */}
                <Col lg="6">
                  <Row form>
                    <label htmlFor="dtexprtm">Expiration Lifespan (Years,Months,Days)</label>
                    <ExpirationLifespan />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </CardBody>
      </Card>
    </Collapse>
  )
}

ExpirationSettings.defaultProps = {
  template: {
    EXPSON: null,
    EXPS: false
  }
}

const mapStateToProps = (state) => ({
  template: state.doctype.DOCTP
})

const mapDispatchToProps = (dispatch) => ({
  update: (obj) => dispatch(updateTemplate(obj))
})

export default connect(mapStateToProps,mapDispatchToProps)(ExpirationSettings)