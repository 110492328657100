import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'

import { Collapse,FormInput,Card,CardHeader,ListGroup,ListGroupItem,Row,Col,FormSelect,Form,Button } from 'shards-react'
import SdxRangeDatePicker from '../common/SdxRangeDatePicker'

import { updateFileJobs,addJobs,updateActiveDoc } from '../../redux/actions/uploadActions'
import { toggleNotificationDropdown } from '../../redux/actions/layoutActions'

const DocumentTypeFields = (props) => {

  const [fld0Value, updateFld0Value] = useState('Choose One...')
  const [fld1Value, updateFld1Value] = useState('Choose One...')
  const [fld2Value, updateFld2Value] = useState('Choose One...')
  const [fld3Value, updateFld3Value] = useState('Choose One...')
  const [fld4Value, updateFld4Value] = useState('Choose One...')

  const {UPLS,DOCTP,updateDoc,addUploads,toggleNotifications,updateJobs} = props
  let DOC = {}
  if(UPLS !== null && UPLS[0] !== undefined) 
    DOC = UPLS[0].DOC

  
  
  return (
    <Collapse
      open={(DOCTP !== null && DOCTP !== undefined) && UPLS !== null && UPLS[0] !== undefined}
    >

      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Document Template Fields</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                {DOCTP !== null && 
                <Form>
                  <Row>
                    {/* LEFT COLUMN */}
                    <Col>
                      {/* DOCUMENT FIELD 0 */}
                      {(DOCTP.FLD0 !== undefined && ((DOCTP.FLD0.RQD == 'true' || DOCTP.FLD0.RQD === true) || DOCTP.FLD0.NM.length > 0)) &&
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld0">{DOCTP.FLD0.NM.length > 0 ? DOCTP.FLD0.NM : 'Field 1'}</label>
                          {DOCTP.FLD0.SLNS !== null ? 
                          <FormSelect value={fld0Value}
                            onChange={(e) => {
                              updateFld0Value(e.target.children[e.target.selectedIndex].value)
                              updateDoc({...DOC,VAL0:e.target.children[e.target.selectedIndex].value})
                            }}>
                              <option value="" default hidden>Choose Value</option>
                              {(DOCTP.FLD0.SLNS !== null && (DOCTP.FLD0.SLNS.OPTS !== null && DOCTP.FLD0.SLNS.OPTS.length > 0)) && DOCTP.FLD0.SLNS.OPTS.map((val,idx) => (
                                <option key={idx}>{val}</option>
                              ))}
                            </FormSelect>
                            :
                            <FormInput
                            id="fld5"
                            placeholder={'Enter value...'} 
                            value={(DOC.VAL0 !== undefined && DOC.VAL0 !== null) ? DOC.VAL0 : ''}
                            onChange={(e) => updateDoc({...DOC,VAL0:e.target.value})}
                            />}
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 1 */}
                      {(DOCTP.FLD1 !== undefined && ((DOCTP.FLD1.RQD === 'true' || DOCTP.FLD1.RQD === true) || DOCTP.FLD1.NM.length > 0)) && 
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld1">{DOCTP.FLD1.NM.length > 0 ? DOCTP.FLD1.NM : 'Field 2'}</label>
                          {DOCTP.FLD1.SLNS !== null ?
                          <FormSelect value={fld1Value}
                            onChange={(e) => {
                              updateFld1Value(e.target.children[e.target.selectedIndex].value)
                              updateDoc({...DOC,VAL1: e.target.children[e.target.selectedIndex].value})
                            }}>
                              <option value="" default hidden>Choose Value</option>
                              {(DOCTP.FLD1.SLNS !== null && (DOCTP.FLD1.SLNS.OPTS !== null && DOCTP.FLD1.SLNS.OPTS.length > 0)) && DOCTP.FLD1.SLNS.OPTS.map((val,idx) => (
                                <option key={idx}>{val}</option>
                              ))}
                            </FormSelect>
                            :
                            <FormInput
                            id="fld5"
                            value={(DOC.VAL1 !== undefined && DOC.VAL1 !== null) ? DOC.VAL1 : ''}
                            placeholder={'Enter value...'} 
                            onChange={(e) => updateDoc({...DOC,VAL1:e.target.value})}
                            />}
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 2 */}
                      {(DOCTP.FLD2 !== undefined && ((DOCTP.FLD2.RQD === 'true' || DOCTP.FLD2.RQD === true) || DOCTP.FLD2.NM.length > 0)) && 
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld2">{DOCTP.FLD2.NM.length > 0 ? DOCTP.FLD2.NM : 'Field 3'}</label>
                          {DOCTP.FLD2.SLNS !== null ?
                          <FormSelect value={fld2Value}
                            onChange={(e) => {
                              updateFld2Value(e.target.children[e.target.selectedIndex].value)
                              updateDoc({...DOC,VAL2:e.target.children[e.target.selectedIndex].value})
                            }}>
                              <option value="" default hidden>Choose Value</option>
                              {(DOCTP.FLD2.SLNS !== null && (DOCTP.FLD2.SLNS.OPTS !== null && DOCTP.FLD2.SLNS.OPTS.length > 0)) && DOCTP.FLD2.SLNS.OPTS.map((val,idx) => (
                                <option key={idx}>{val}</option>
                              ))}
                            </FormSelect>
                            :
                            <FormInput
                            id="fld5"
                            placeholder={'Enter value...'} 
                            value={(DOC.VAL2 !== undefined && DOC.VAL2 !== null) ? DOC.VAL2 : ''}
                            onChange={(e) => updateDoc({...DOC,VAL2:e.target.value})}
                            />}
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 3 */}
                      {(DOCTP.FLD3 !== undefined && ((DOCTP.FLD3.RQD === 'true' || DOCTP.FLD3.RQD === true) || DOCTP.FLD3.NM.length > 0)) && 
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld0">{DOCTP.FLD3.NM.length > 0 ? DOCTP.FLD3.NM : 'Field 4'}</label>
                          {DOCTP.FLD3.SLNS !== null ?
                          <FormSelect value={fld3Value}
                            onChange={(e) => {
                              updateFld3Value(e.target.children[e.target.selectedIndex].value)
                              updateDoc({...DOC,VAL3: e.target.children[e.target.selectedIndex].value})
                            }}>
                              <option value="" default hidden>Choose Value</option>
                              {(DOCTP.FLD3.SLNS !== null && (DOCTP.FLD3.SLNS.OPTS !== null && DOCTP.FLD3.SLNS.OPTS.length > 0)) && DOCTP.FLD3.SLNS.OPTS.map((val,idx) => (
                                <option key={idx}>{val}</option>
                              ))}
                            </FormSelect>
                            :
                            <FormInput
                            id="fld5"
                            placeholder={'Enter value...'} 
                            value={(DOC.VAL3 !== undefined && DOC.VAL3 !== null) ? DOC.VAL3 : ''}
                            onChange={(e) => updateDoc({...DOC,VAL3:e.target.value})}
                            />}
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 4 */}
                      {(DOCTP.FLD4 !== undefined && ((DOCTP.FLD4.RQD === 'true' || DOCTP.FLD4.RQD === true) || DOCTP.FLD4.NM.length > 0)) && 
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld4">{DOCTP.FLD4.NM.length > 0 ? DOCTP.FLD4.NM : 'Field 5'}</label>
                          {DOCTP.FLD4.SLNS !== null ?
                          <FormSelect value={fld4Value}
                            onChange={(e) => {
                              updateFld4Value(e.target.children[e.target.selectedIndex].value)
                              updateDoc({...DOC,VAL4: e.target.children[e.target.selectedIndex].value})
                            }}>
                              <option value="" default hidden>Choose Value</option>
                              {(DOCTP.FLD4.SLNS !== null && (DOCTP.FLD4.SLNS.OPTS !== null && DOCTP.FLD4.SLNS.OPTS.length > 0)) && DOCTP.FLD4.SLNS.OPTS.map((val,idx) => (
                                <option key={idx}>{val}</option>
                              ))}
                            </FormSelect>
                            :
                            <FormInput
                            id="fld5"
                            placeholder={'Enter value...'} 
                            value={(DOC.VAL4 !== undefined && DOC.VAL4 !== null) ? DOC.VAL4 : ''}
                            onChange={(e) => updateDoc({...DOC,VAL4:e.target.value})}
                            />}
                        </Col>
                      </Row>}
                    </Col>
                    {/* RIGHT COLUMN */}
                    <Col>
                      {/* DOCUMENT FIELD 5 */}
                      {(DOCTP.FLD5 !== undefined && ((DOCTP.FLD5.RQD === 'true' || DOCTP.FLD5.RQD === true) || DOCTP.FLD5.NM.length > 0)) && 
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld5">{DOCTP.FLD5.NM.length > 0 ? DOCTP.FLD5.NM : 'Field 6'}</label>
                          <FormInput
                            pattern="[0-9]*"
                            id="fld5"
                            placeholder={'Enter value...'} 
                            value={(DOC.VAL5 !== undefined && DOC.VAL5 !== null) ? DOC.VAL5 : ''}
                            onChange={(e) => {
                              if(e.target.validity.valid)
                                updateDoc({...DOC,VAL5:e.target.value})
                            }}
                            />
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 6 */}
                      {(DOCTP.FLD6 !== undefined && ((DOCTP.FLD6.RQD === 'true' || DOCTP.FLD6.RQD === true) || DOCTP.FLD6.NM.length > 0)) &&
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld5">{DOCTP.FLD6.NM.length > 0 ? DOCTP.FLD6.NM : 'Field 6'}</label>
                          <FormInput
                            pattern="[0-9]*"
                            id="fld5"
                            placeholder={'Enter value...'} 
                            value={(DOC.VAL6 !== undefined && DOC.VAL6 !== null) ? DOC.VAL6 : ''}
                            onChange={(e) => {
                              if(e.target.validity.valid)
                                updateDoc({...DOC,VAL6:e.target.value})
                            }}
                            />
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 7 */}
                      {(DOCTP.FLD7 !== undefined && ((DOCTP.FLD7.RQD === 'true' || DOCTP.FLD7.RQD === true) || DOCTP.FLD7.NM.length > 0)) && 
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld5">{DOCTP.FLD7.NM.length > 0 ? DOCTP.FLD7.NM : 'Field 6'}</label>
                          <FormInput
                            pattern="[0-9]*"
                            id="fld5"
                            placeholder={'Enter value...'} 
                            value={(DOC.VAL7 !== undefined && DOC.VAL7 !== null) ? DOC.VAL7 : ''}
                            onChange={(e) => {
                              if(e.target.validity.valid)
                              updateDoc({...DOC,VAL7:e.target.value})
                            }}
                            />
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 8 */}
                      {(DOCTP.FLD8 !== undefined && ((DOCTP.FLD8.RQD === 'true' || DOCTP.FLD8.RQD === true) || DOCTP.FLD8.NM.length > 0)) && 
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld5">{DOCTP.FLD8.NM.length > 0 ? DOCTP.FLD8.NM : 'Field 6'}</label>
                          <SdxRangeDatePicker 
                            endValue={DOC.VAL8 !== undefined && DOC.VAL8 !== null ? new Date(Number(DOC.VAL8)) : undefined}
                            onChange={(value) => updateDoc({...DOC,VAL8:value.getTime().toString()})}
                            onClear={() => updateDoc({...DOC,VAL8:undefined})}
                            />
                        </Col>
                      </Row>}
                      {/* DOCUMENT FIELD 9 */}
                      {(DOCTP.FLD9 !== undefined && ((DOCTP.FLD9.RQD === 'true' || DOCTP.FLD9.RQD === true) || DOCTP.FLD9.NM.length > 0)) &&
                      <Row form>
                        <Col lg className="form-group">
                          <label htmlFor="fld5">{DOCTP.FLD9.NM.length > 0 ? DOCTP.FLD9.NM : 'Field 6'}</label>
                          <SdxRangeDatePicker 
                            endValue={DOC.VAL9 !== undefined && DOC.VAL9 !== null ? new Date(Number(DOC.VAL9)) : undefined}
                            onChange={(value) => updateDoc({...DOC,VAL9:value.getTime().toString()})}
                            onClear={() => updateDoc({...DOC,VAL9:undefined})}
                            />
                        </Col>
                      </Row>}
                    </Col>
                  </Row>
                  {/* COMPLETE UPLOAD */}
                  <Row form>
                    <Button 
                      // disabled={}
                      onClick={() => {
                        addUploads(UPLS)
                        toggleNotifications()
                        updateJobs([])
                      }}
                      theme="primary">Upload</Button>
                  </Row>
                </Form>}
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Collapse>

  )
}


const mapStateToProps = state => ({
  UPLS: state.upload.fileJobs,
  open: state.layout.doctypeFieldsOpen,
  DOCTP: state.upload.activeDocType
})

// ADD UPDATEDOC METHOD

const mapDispatchToProps = dispatch => ({
  updateJobs: (jobs) => dispatch(updateFileJobs(jobs)),
  addUploads: (jobs) => dispatch(addJobs(jobs)),
  toggleNotifications: () => dispatch(toggleNotificationDropdown()),
  updateDoc: (DOC) => dispatch(updateActiveDoc(DOC))
})

export default connect(mapStateToProps,mapDispatchToProps)(DocumentTypeFields)