import React, { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import { fetchAccountDocumentTypes } from '../../redux/actions/accountActions'
import { updateFileJobs,addJobs,setActiveUploadDocType,clearActiveUploadDocType } from '../../redux/actions/uploadActions'
import { toggleNotificationDropdown } from '../../redux/actions/layoutActions'
//COMPONENTS
import { Collapse,Card,CardHeader,CardBody,Col,Row,InputGroup,InputGroupText,InputGroupAddon,FormSelect } from 'shards-react'


const AddUploads = (props) => {

  const {acct,uploads,fetchDocTypes,updateJobs,DOCTPS,toggleNotifications,addUploads,updateActiveDocType,clearDocType} = props

  const [dropDownValue,updateDropdown] = useState('Choose One...')

  useEffect(() => {
    if(DOCTPS === undefined || DOCTPS === null) {
      fetchDocTypes({ACCT:acct.ID,TJT:'FULL'})
    }else if((DOCTPS !== null && DOCTPS !== undefined) ? DOCTPS.length === 0 : false) {
      fetchDocTypes({ACCT:acct.ID,TJT:'FULL'})
    }
  })

  
  return (
    <Collapse open={uploads.length > 0} >
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Uploads</h6>
        </CardHeader>
        <CardBody>
          <Col>
            {uploads.map((upl,idx) => (
              <Row key={idx}>
                <InputGroup>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>{upl.FLNM}</InputGroupText>
                  </InputGroupAddon>
                  <FormSelect value={dropDownValue}
                    onChange={(e) => {
                        uploads[uploads.indexOf(upl)] = {
                          ...upl,
                          DOC: {
                            ...upl.DOC,
                            DOCTP: {
                              ID: e.target.children[e.target.selectedIndex].attributes.getNamedItem('sdx-id').value
                            }
                          }
                        }
                        updateDropdown(e.target.children[e.target.selectedIndex].value)
                        updateJobs(uploads)
                        updateActiveDocType(e.target.children[e.target.selectedIndex].attributes.getNamedItem('sdx-id').value)
                      }}>
                      <option value="" default hidden>Choose one...</option>
                    {(DOCTPS !== undefined && DOCTPS !== null) && DOCTPS.map((dt,idx) => (
                      <option key={idx} sdx-id={dt.ID}>{dt.NM}</option>
                    ))}
                  </FormSelect>
                  <InputGroupAddon type="append">
                    <InputGroupText>
                      <i onClick={() => {
                        let upls = uploads
                        upls.splice(upls.indexOf(upl),1)
                        updateJobs([...upls])
                        clearDocType()
                        updateDropdown('Choose one...')
                      }}
                        className="material-icons sdx-input-group-clickable">clear</i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Row>
            ))}
          </Col>
        </CardBody>
      </Card>
    </Collapse>
  )
}

AddUploads.defaultProps = {
  uploads: [],
  DOCTPS: []
}

const mapStateToProps = (state) => ({
  uploads: state.upload.fileJobs,
  acct: state.acct.ACCT,
  DOCTPS: state.acct.DOCTPS
})

const mapDispatchToProps = (dispatch) => ({
  fetchDocTypes: (ID) => dispatch(fetchAccountDocumentTypes(ID)),
  updateJobs: (jobs) => dispatch(updateFileJobs(jobs)),
  addUploads: (jobs) => dispatch(addJobs(jobs)),
  toggleNotifications: () => dispatch(toggleNotificationDropdown()),
  updateActiveDocType: (Id) => dispatch(setActiveUploadDocType(Id)),
  clearDocType: () => dispatch(clearActiveUploadDocType())
})

export default connect(mapStateToProps,mapDispatchToProps)(AddUploads)