import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import {
	setActiveDirectory,
	setDirectories
} from '../../redux/actions/repoActions'
import { toggleAddDirCollapse } from '../../redux/actions/layoutActions'
import { Button } from 'shards-react'

const mapStateToProps = state => ({
	FLDRS: state.repo.directories,
	repo: state.repo.repo,
	acct: state.acct.ACCT,
	loading: state.repo.update,
	toggleTips: state.layout.toggleHideTooltips
})

const mapDispatchToProps = dispatch => ({
	setDir: (FLDR) => dispatch(setActiveDirectory(FLDR)),
	setBreadcrumbs: (FLDR) => dispatch(setDirectories(FLDR)),
	toggleAddDir: () => dispatch(toggleAddDirCollapse())
})

export default connect(mapStateToProps, mapDispatchToProps)((props) => {


	return (
		<div>

			{props.FLDRS.map((fldr, idx) => (
				<span key={idx}>
					<a
						className={idx === props.FLDRS.length - 1 ? '' : 'sdx-bc-link'}
						id={idx}
						key={idx}
						onClick={(e) => {
							if (idx === props.FLDRS.length - 1) {
								//do nothing
							} else {
								props.setBreadcrumbs(fldr)
								props.setDir({ ACCT: props.acct.ID, REPO: props.repo.ID, FLDR: fldr.ID, DIR: fldr })
							}
						}}
					>{fldr.NM} / </a>
				</span>
			))}
			<Button
				data-for="add-dir"
				data-tip="add a new Folder at the current location"
				onClick={() => props.toggleAddDir()}
				theme="">
				<span className="material-icons" style={{ color: 'blue' }}>create_new_folder</span>
			</Button>
			<ReactTooltip
				disable={props.toggleTips}
				id="add-dir"
			/>
		</div>
	)
})