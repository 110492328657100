import url from '../../connection/url'
import axios from '../../connection/axiosHandler'

import state from '../store'

import {
	validateSessionError,
	authenticateFailure
} from './authActions'


export const FETCH_REPO_BEGIN = "FETCH_REPO_BEGIN"
export const FETCH_REPO_SUCCESS = "FETCH_REPO_SUCCESS"
export const FETCH_REPO_FAILURE = "FETCH_REPO_FAILURE"

export const FETCH_REPO_LIST_BEGIN = "FETCH_REPO_LIST_BEGIN"
export const FETCH_REPO_LIST_SUCCESS = "FETCH_REPO_LIST_SUCCESS"
export const FETCH_REPO_LIST_FAILURE = "FETCH_REPO_LIST_FAILURE"

export const FETCH_ROOT_FOLDER_BEGIN = "FETCH_ROOT_FOLDER_BEGIN"
export const FETCH_ROOT_FOLDER_SUCCESS = "FETCH_ROOT_FOLDER_SUCCESS"
export const FETCH_ROOT_FOLDER_FAILURE = "FETCH_ROOT_FOLDER_FAILURE"

export const FETCH_SUBFOLDERS_BEGIN = "FETCH_SUBFOLDERS_BEGIN"
export const FETCH_SUBFOLDERS_SUCCESS = "FETCH_SUBFOLDERS_SUCCESS"
export const FETCH_SUBFOLDERS_FAILURE = "FETCH_SUBFOLDERS_FAILURE"

export const FETCH_DOCUMENTS_BEGIN = "FETCH_DOCUMENTS_BEGIN"
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS"
export const FETCH_DOCUMENTS_FAILURE = "FETCH_DOCUMENTS_FAILURE"

export const FETCH_DOCUMENTS_BATCH_BEGIN = "FETCH_DOCUMENTS_BATCH_BEGIN"
export const FETCH_DOCUMENTS_BATCH_SUCCESS = "FETCH_DOCUMENTS_BATCH_SUCCESS"
export const FETCH_DOCUMENTS_BATCH_FAILURE = "FETCH_DOCUMENTS_BATCH_FAILURE"

export const SET_ACTIVE_DIR_BEGIN = "SET_ACTIVE_DIR_BEGIN"
export const SET_ACTIVE_DIR_SUCCESS = "SET_ACTIVE_DIR_SUCCESS"
export const SET_ACTIVE_DIR_FAILURE = "SET_ACTIVE_DIR_FAILURE"

export const SET_ACTIVE_REPO = "SET_ACTIVE_REPO"
export const SET_ACTIVE_DIR_CONTENTS = "SET_ACTIVE_DIR_CONTENTS"

export const FETCH_ACTIVE_DIR_CONTENTS_BATCH_BEGIN = "FETCH_ACTIVE_DIR_CONTENTS_BATCH_BEGIN"
export const FETCH_ACTIVE_DIR_CONTENTS_BATCH_SUCCESS = "FETCH_ACTIVE_DIR_CONTENTS_BATCH_SUCCESS"
export const FETCH_ACTIVE_DIR_CONTENTS_BATCH_FAILURE = "FETCH_ACTIVE_DIR_CONTENTS_BATCH_FAILURE"

export const SET_BATCH_FIRST_LOAD = "SET_BATCH_FIRST_LOAD"

export const SET_DIRECTORY_LIST = "SET_DIRECTORY_LIST"

export const ADD_DIR_BEGIN = "ADD_DIR_BEGIN"
export const ADD_DIR_SUCCESS = "ADD_DIR_SUCCESS"
export const ADD_DIR_FAILURE = "ADD_DIR_FAILURE"

export const UPDATE_NEW_DIR = "UPDATE_NEW_DIR"

export const REMOVE_DIR_BEGIN = "REMOVE_DIR_BEGIN"
export const REMOVE_DIR_SUCCESS = "REMOVE_DIR_SUCCESS"
export const REMOVE_DIR_FAILURE = "REMOVE_DIR_FAILURE"

export const REMOVE_DOC_BEGIN = "REMOVE_DOC_BEGIN"
export const REMOVE_DOC_SUCCESS = "REMOVE_DOC_SUCCESS"
export const REMOVE_DOC_FAILURE = "REMOVE_DOC_FAILURE"

export const UPDATE_SEARCH_LIST = "UPDATE_SEARCH_LIST"
export const RESET_SEARCH_LIST = "RESET_SEARCH_LIST"

export const UPDATE_DOCUMENTS_PAGINATION = "UPDATE_DOCUMENTS_PAGINATION"

export const updateDocumentsPagination = (pagination) => ({
	type: UPDATE_DOCUMENTS_PAGINATION,
	payload: pagination
})

export const fetchRepoBegin = () => ({
	type: FETCH_REPO_BEGIN
})

export const fetchRepoSuccess = (REPO) => ({
	type: FETCH_REPO_SUCCESS,
	payload: REPO
})

export const fetchRepoFailure = (ERROR) => ({
	type: FETCH_REPO_FAILURE,
	payload: ERROR
})

export const fetchRepoListBegin = () => ({
	type: FETCH_REPO_LIST_BEGIN
})

export const fetchRepoListSuccess = (REPOS) => ({
	type: FETCH_REPO_LIST_SUCCESS,
	payload: REPOS
})

export const fetchRepoListFailure = (ERROR) => ({
	type: FETCH_REPO_LIST_FAILURE,
	payload: ERROR
})

export const fetchRootFolderBegin = () => ({
	type: FETCH_ROOT_FOLDER_BEGIN
})

export const fetchRootFolderSuccess = (FLDR) => ({
	type: FETCH_ROOT_FOLDER_SUCCESS,
	payload: FLDR
})

export const fetchRootFolderFailure = (ERROR) => ({
	type: FETCH_ROOT_FOLDER_FAILURE,
	payload: ERROR
})

export const fetchSubfoldersBegin = () => ({
	type: FETCH_SUBFOLDERS_BEGIN
})

export const fetchSubfoldersSuccess = (REPO) => ({
	type: FETCH_SUBFOLDERS_SUCCESS,
	payload: REPO
})

export const fetchSubfoldersFailure = (ERROR) => ({
	type: FETCH_SUBFOLDERS_FAILURE,
	payload: ERROR
})

export const fetchDocumentsBegin = () => ({
	type: FETCH_DOCUMENTS_BEGIN
})

export const fetchDocumentsSuccess = (contents) => ({
	type: FETCH_DOCUMENTS_SUCCESS,
	payload: contents
})

export const fetchDocumentsFailure = (ERROR) => ({
	type: FETCH_DOCUMENTS_FAILURE,
	payload: ERROR
})

export const fetchDocumentsBatchBegin = () => ({
	type: FETCH_DOCUMENTS_BATCH_BEGIN
})

export const fetchDocumentsBatchSuccess = (activeRepo, activeDir) => ({
	type: FETCH_DOCUMENTS_BATCH_SUCCESS,
	payload: { activeRepo, activeDir }
})

export const fetchDocumentsBatchFailure = (ERROR) => ({
	type: FETCH_DOCUMENTS_BATCH_FAILURE,
	payload: ERROR
})

export const fetchFolderContentsBatchBegin = () => ({
	type: FETCH_ACTIVE_DIR_CONTENTS_BATCH_BEGIN
})

export const fetchFolderContentsBatchSuccess = (list) => ({
	type: FETCH_ACTIVE_DIR_CONTENTS_BATCH_SUCCESS,
	payload: list
})

export const fetchFolderContentsBatchFailure = (error) => ({
	type: FETCH_ACTIVE_DIR_CONTENTS_BATCH_FAILURE,
	payload: error
})

export const setBatchFirstLoad = (bool) => ({
	type: SET_BATCH_FIRST_LOAD,
	payload: bool
})

export const setActiveDirBegin = () => ({
	type: SET_ACTIVE_DIR_BEGIN
})

export const setActiveDirSuccess = (FLDR) => ({
	type: SET_ACTIVE_DIR_SUCCESS,
	payload: FLDR
})

export const setActiveDirFailure = (ERR) => ({
	type: SET_ACTIVE_DIR_FAILURE,
	payload: ERR
})

export const setActiveRepository = (REPO) => ({
	type: SET_ACTIVE_REPO,
	payload: REPO
})

export const setActiveDirectoryContents = (list) => ({
	type: SET_ACTIVE_DIR_CONTENTS,
	payload: list
})

export const setDirectoryList = (list) => ({
	type: SET_DIRECTORY_LIST,
	payload: list
})

export const addDirBegin = () => ({
	type: ADD_DIR_BEGIN
})

export const addDirSuccess = (list) => ({
	type: ADD_DIR_SUCCESS,
	payload: list
})

export const addDirFailure = (ERR) => ({
	type: ADD_DIR_FAILURE,
	payload: ERR
})

export const updateNewDir = (FLDR) => ({
	type: UPDATE_NEW_DIR,
	payload: FLDR
})

export const removeDirBegin = () => ({
	type: REMOVE_DIR_BEGIN
})

export const removeDirSuccess = (list) => ({
	type: REMOVE_DIR_SUCCESS,
	payload: list
})

export const removeDirFailure = (ERR) => ({
	type: REMOVE_DIR_FAILURE,
	payload: ERR
})

export const removeDocBegin = () => ({
	type: REMOVE_DOC_BEGIN
})

export const removeDocSuccess = (list) => ({
	type: REMOVE_DOC_SUCCESS,
	payload: list
})

export const removeDocFailure = (ERR) => ({
	type: REMOVE_DOC_FAILURE,
	payload: ERR
})

export const _updateSearchList = (contents) => ({
	type: UPDATE_SEARCH_LIST,
	payload: contents
})

export const resetSearchList = () => ({
	type: RESET_SEARCH_LIST
})

export const fetchRepoList = (ID) => {
	return async dispatch => {
		if ((ID.ACCT !== undefined) && (ID.ACCT !== null)) {
			dispatch(fetchRepoListBegin())
			try {
				const response = await axios({
					baseURL: `${url}/accts/${ID.ACCT}/repos`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'post',
					data: {
						TJT: ID.JSON ? ID.JSON : 'FULL'
					}
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(fetchRepoListFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					dispatch(fetchRepoListSuccess(response.data.RSP.REPOS))
				} else {
					dispatch(fetchRepoListFailure('fetchRepoList: Unknown Error while fetching repository list for account [' + ID.ACCT + '].'))
				}
			} catch (error) {
				dispatch(fetchRepoListFailure(error))
			}
		} else {
			dispatch(fetchRepoListFailure('fetchRepoList: Unable to verify Account id.'))
		}
	}
}

/**
 * fetchRepo is responsible for getting the Account's Repository.
 * 
 * @param {ID Obj} id 
 */
export const fetchRepo = (ID) => {
	return async dispatch => {
		if (ID.ACCT && ID.REPO) {
			dispatch(fetchRepoBegin())
			try {
				const response = await axios({
					baseURL: `${url}/accts/${ID.ACCT}/repos/${ID.REPO}`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'get'
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(fetchRepoFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					dispatch(fetchRepoSuccess(response.data.RSP.REPO))
				} else {
					dispatch(fetchRepoFailure('fetchRepository: Unknown Error while fetching Repository for Acct [' + ID.ACCT + '].'))
				}
			} catch (error) {
				dispatch(fetchRepoFailure(error))
			}
		} else {
			dispatch(fetchRepoFailure('fetchRepository: Unable to verify Acct id and Repo id.'))
		}
	}
}

export const fetchRootFolder = (ID) => {
	return async dispatch => {
		if (ID.ACCT && ID.REPO && ID.FLDR) {
			dispatch(fetchRootFolderBegin())
			try {
				const response = await axios({
					baseURL: `${url}/ui/${ID.ACCT}/fldrs/${ID.FLDR}`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'post',
					data: {
						TJT: 'UI'
					}
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(fetchRootFolderFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					dispatch(fetchRootFolderSuccess({ ...response.data.RSP.FLDR, CNT: response.data.RSP.CNT }))
				} else {
					dispatch(fetchRootFolderFailure('fetchRootFolder: Unknown Error while fetching root folder.'))
				}
			} catch (error) {
				dispatch(fetchRootFolderFailure(error))
			}
		} else {
			dispatch(fetchRootFolderFailure('fetchRootFolder: Unable to verify Acct id, Repo id, and Root Folder id.'))
		}
	}
}

export const fetchSubfolders = (ID) => {
	return async dispatch => {
		if (ID.ACCT && ID.REPO && ID.FLDR) {
			dispatch(fetchSubfoldersBegin())
			try {
				const response = await axios({
					baseURL: `${url}/accts/${ID.ACCT}/repos/${ID.REPO}/fldrs/${ID.FLDR}/fldrs`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'post',
					data: {
						TJT: 'FULL'
					}
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(fetchSubfoldersFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					//REBUILD REPOSITORY OBJECT WITH FOLDERS INSIDE
					if (state.getState().repo.activeRepo && state.getState().repo.activeRepo.FLDR) {
						let { activeRepo } = state.getState().repo
						//IF SUBFOLDERS ARE IMMEDIATELY UNDER ROOT FOLDER
						if (activeRepo.FLDR.ID === ID.FLDR) {
							activeRepo.FLDR.FLDRS = response.data.RSP.FLDRS
							dispatch(fetchSubfoldersSuccess(activeRepo))
						} else if (activeRepo.FLDR.FLDRS !== null ? activeRepo.FLDR.FLDRS.length > 0 : false) {
							for (var fldr in activeRepo.FLDR.FLDRS) {
								//FOUND MATCHING PARENT FOLDER, ADDING SUBFOLDERS
								if (fldr.ID === ID.FLDR) {
									fldr.FLDRS = response.data.RSP.FLDRS
									break
								}
							}
							dispatch(fetchSubfoldersSuccess(activeRepo))
						} else {
							dispatch(fetchSubfoldersFailure('fetchSubfolders: Unable to determine parent Folder in state.'))
						}
					} else {
						dispatch(fetchSubfoldersFailure('fetchSubfolders: Unable to find valid Repository in state.'))
					}
				} else {
					dispatch(fetchSubfoldersFailure('fetchsubfolders: Unknown Error while fetching root folder.'))
				}
			} catch (error) {
				dispatch(fetchSubfoldersFailure(error))
			}
		} else {
			dispatch(fetchSubfoldersFailure('fetchSubfolders: Unable to verify Acct id, Repo id, and Root Folder id.'))
		}
	}
}


export const fetchDocuments = (ID) => {
	return async dispatch => {
		if(ID.ACCT && ID.REPO && ID.FLDR) {
			dispatch(fetchDocumentsBegin())
			const response = await axios({
				baseURL: `${url}/ui/${ID.ACCT}/repos/${ID.REPO}/fldrs/${ID.FLDR}/docs/pagination`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'post',
					data: {
							PAGE: ID.PAGE || 0,
							PAGE_SIZE: ID.PAGE_SIZE || 5,
							QUERY: ID.QUERY || '',
							TJT: ID.TJT || 'UI'
					}
			})
			if(response.data.ERR !== null) {
				if(validateSessionError(response.data.ERR))
					dispatch(authenticateFailure())
				dispatch(fetchDocumentsFailure(response.data.ERR))
			}else if (response.data.RSP.DOCS && response.data.RSP.PAGINATION) {
				dispatch(updateDocumentsPagination(response.data.RSP.PAGINATION))
				dispatch(fetchDocumentsSuccess(response.data.RSP))
			}else {
				dispatch(fetchDocumentsFailure('fetchDocuments.error: No Documents Returned.'))
			}
		}else {
			dispatch(fetchDocumentsFailure('fetchDocuments.error: Missing Required Id.'))
		}
	}
}


export const fetchDocsBatched = (ID) => {
	return async dispatch => {
		if (ID.ACCT && ID.REPO && ID.FLDR && ID.BEGIN && ID.END) {
			dispatch(fetchDocumentsBegin())
			try {
				const response = await axios({
					baseURL: `${url}/accts/${ID.ACCT}/repos/${ID.REPO}/fldrs/${ID.FLDR}/docs/${ID.BEGIN}/${ID.END}`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'post',
					data: {
						TJT: 'FULL'
					}
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(fetchDocumentsBatchFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					//REBUILD REPOSITORY OBJECT WITH FOLDERS INSIDE
					if (state.getState().repo.activeRepo && state.getState().repo.activeRepo.FLDR) {
						let { activeRepo, activeDir } = state.getState().repo
						//IF DOCUMENTS ARE PART OF ACTIVEDIR FOLDER
						if (activeDir.ID == ID.FLDR) {
							activeDir.DOCS = activeDir.DOCS.concat(response.data.RSP.DOCS)
							dispatch(fetchDocumentsBatchSuccess(activeRepo, activeDir))
						}
						//IF DOCUMENTS ARE IMMEDIATELY UNDER ROOT FOLDER
						if (activeRepo.FLDR.ID === ID.FLDR) {
							activeRepo.FLDR.DOCS = activeRepo.FLDR.DOCS.concat(response.data.RSP.DOCS)
							//INSERT ITEMS INTO DOCS LIST AT THEIR GIVEN INDEXES
							for (let i = ID.BEGIN; i < ID.END; i++) {
								activeRepo.FLDR.DOCS[i] = response.data.RSP.DOCS[i]
							}
							dispatch(fetchDocumentsBatchSuccess(activeRepo, activeDir))
						} else if (activeRepo.FLDR.FLDRS !== null ? activeRepo.FLDR.FLDRS.length > 0 : false) {
							for (var fldr in activeRepo.FLDR.FLDRS) {
								//FOUND MATCHING PARENT FOLDER, ADDING DOCUMENTS
								if (fldr.ID === ID.FLDR) {
									fldr.DOCS = fldr.DOCS.concat(response.data.RSP.DOCS)
									break
								}
							}
							dispatch(fetchDocumentsBatchSuccess(activeRepo, activeDir))
						} else {
							dispatch(fetchDocumentsBatchFailure('fetchDocuments: Unable to determine parent Folder in state.'))
						}
					} else {
						dispatch(fetchDocumentsBatchFailure('fetchDocuments: Unable to find valid Repository in state.'))
					}
				} else {
					dispatch(fetchDocumentsBatchFailure('fetchDocuments: Unknown Error while fetching documents for folder id [' + ID.FLDR + '].'))
				}
			} catch (error) {
				dispatch(fetchDocumentsBatchFailure(error))
			}
		} else {
			dispatch(fetchDocumentsBatchFailure('fetchDocuments: Unable to verify Acct id, Repo id, and Folder id.'))
		}
	}
}

export const fetchFolderContentsBatched = (ID) => {
	return async dispatch => {
		if (ID.ACCT !== undefined && ID.REPO !== undefined && ID.FLDR !== undefined && ID.BEGIN !== undefined && ID.END !== undefined) {
			dispatch(fetchFolderContentsBatchBegin())
			if (ID.TJT === 'UI' && (ID.BEGIN === 0 && ID.END > 0)) {
				dispatch(setBatchFirstLoad(true))
			}
			try {
				const response = await axios({
					baseURL: `${url}/ui/${ID.ACCT}/repos/${ID.REPO}/fldrs/${ID.FLDR}/docs/${ID.BEGIN}/${ID.END}`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'post',
					data: {
						TJT: ID.TJT
					}
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(fetchFolderContentsBatchFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					if (response.data.RSP.DOCS !== null ? response.data.RSP.DOCS.length > 0 : false) {
						let contents = state.getState().repo.activeDirContents
						if (Number(state.getState().repo.activeDir.ID) !== Number(ID.FLDR)) {
							dispatch(fetchFolderContentsBatchFailure('fetchFolderContents: the activeDir does not match the folder id.'))
							console.log('fetchFolderContents: the activeDir does not match the folder id.')
						} else {
							if (contents !== null ? contents.length >= ID.END : false) {
								for (var item of response.data.RSP.DOCS) {
									contents[item.IDX] = (item.DOC !== undefined ? item.DOC : (item.FLDR !== undefined ? item.FLDR : null))
								}
								if (ID.TJT === 'UI' && (ID.BEGIN === 0 && ID.END > 0)) {
									dispatch(setBatchFirstLoad(false))
								}
								dispatch(fetchFolderContentsBatchSuccess(contents))
							} else {
								if (state.getState().repo.activeDir.ID !== ID.FLDR) {
									// the activeDir was changed before the call resolved. do nothing.
								} else {
									dispatch(fetchFolderContentsBatchFailure('fetchFolderContents: either the display contents were empty or the ending index was incorrect.'))
								}
							}
						}
					} else
						dispatch(fetchFolderContentsBatchFailure('fetchFolderContents: the response was null or empty.'))
				} else
					dispatch(fetchFolderContentsBatchFailure('fetchFolderContents: Unknown Error while fetching documents for folder id [' + ID.FLDR + '].'))
			} catch (error) {
				dispatch(fetchFolderContentsBatchFailure(error))
			}
		}
	}
}

/**
 * takes an object containing the following structure,
 * with the ID.DIR param being the FLDR object of the activeDir or 
 * the activeDir to set.
 * 
 * @param { ID.ACCT, ID.REPO, ID.FLDR, ID.DIR } ID 
 */
export const setActiveDirectory = (ID) => {
	console.log('setActiveDirectory called')
	return async dispatch => {
		if (ID.DIR.TJT === "ID") {
			dispatch(setActiveDirBegin())
			const response = await axios({
				baseURL: `${url}/ui/${ID.ACCT}/fldrs/${ID.DIR.ID}`,
				crossDomain: true,
				timeout: 200000,
				withCredentials: true,
				credentials: 'same-origin',
				responseType: 'json',
				method: 'post',
				data: {
					TJT: "UI"
				}
			})
			if (response.data.ERR !== null) {
				if (validateSessionError(response.data.ERR))
					dispatch(authenticateFailure())
				console.log('setActiveDirectory.getFolder: Failed to get TJT.FULL version of FOLDER.')
			} else if (response.data.RSP !== null) {
				dispatch(setActiveDirSuccess({ ...response.data.RSP.FLDR, CNT: response.data.RSP.CNT }))
				dispatch(setDirectories(response.data.RSP.FLDR))
			}
		} else {
			dispatch(setActiveDirSuccess(ID.DIR))
			dispatch(setDirectories(ID.DIR))
		}
		// dispatch(fetchDocuments(ID))
	}
}


export const setActiveRepo = (ID) => {
	return async dispatch => {
		dispatch(setActiveRepository(ID.REPO))
		dispatch(fetchRootFolder(ID))
	}
}

export const setActiveDirContents = (contents) => {
	return async dispatch => {
		dispatch(setActiveDirectoryContents(contents))
	}
}

export const setDirectories = (FLDR) => {
	return async dispatch => {
		if (FLDR !== null) {
			let fldrs
			if (state.getState().repo.directories !== null) {
				fldrs = state.getState().repo.directories
				if (fldrs.length > 0) {
					let existingFldr = fldrs.find((fldr1) => {
						return fldr1.ID == FLDR.ID
					})
					if (existingFldr !== undefined) {
						let idx = fldrs.indexOf(existingFldr)
						fldrs.splice(idx, (fldrs.length - idx))
						dispatch(setDirectoryList(fldrs))
					} else {
						fldrs.push(FLDR)
						dispatch(setDirectoryList(fldrs))
					}
				} else {
					fldrs.push(FLDR)
					dispatch(setDirectoryList(fldrs))
				}
			} else {
				console.log('the directory breadcrumb list was null.')
			}
		} else {
			console.log('the folder to add to the breadcrumbs was null.')
		}
	}
}

export const addNewDirectory = (ID) => {
	return async dispatch => {
		if (ID.ACCT !== undefined && ID.REPO !== undefined && ID.FLDR !== undefined && ID.DIR !== undefined) {
			try {
				const response = await axios({
					baseURL: `${url}/accts/${ID.ACCT}/repos/${ID.REPO}/fldrs/${ID.FLDR}/add`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'post',
					data: ID.DIR
				})
				// console.log('response: ', response)
				if (response.data.ERR !== null) {
					
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(addDirFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					let acct = state.getState().acct
					let repo = state.getState().repo
					
					if(response.data.RSP.FLDR !== null) {
						console.log('fetching documents?')
						dispatch(fetchDocuments({ ACCT:acct.ACCT.ID, REPO:repo.activeRepo.ID, FLDR: repo.activeDir.ID, PAGE: repo.activeDirContentsPagination.PAGE, 
							PAGE_SIZE:repo.activeDirContentsPagination.PAGE_SIZE, QUERY:repo.activeDirContentsPagination.QUERY }))
					}
					
					// if (contents !== undefined ? contents !== null ? contents.length > 0 : false : false) {
					// 	for (let item of contents) {
					// 		if (item.TP === 'FLDR') {
					// 			continue
					// 		} else if (item.TP === 'DOC') {
					// 			let newcontents = contents.splice(contents.indexOf(item), 0, { ...response.data.RSP.FLDR, TP: 'FLDR' })
					// 			dispatch(addDirSuccess(newcontents))
					// 			break
					// 		}
					// 	}
					// } else {
					// 	let newcontents = []
					// 	newcontents.push({ ...response.data.RSP.FLDR, TP: 'FLDR' })
					// 	dispatch(addDirSuccess(newcontents))
					// }
				}else {
					console.log('response not parsed??')
				}
			} catch (error) {
				dispatch(addDirFailure(error))
			}
		} else {
			dispatch(addDirFailure('addNewDirectory: Unable to verify correct parameters for adding the new Directory.'))
		}
	}
}

export const removeDirectory = (ID, callbackID) => {
	return async dispatch => {
		if (ID.ACCT !== undefined && ID.REPO !== undefined && ID.FLDR !== undefined) {
			dispatch(removeDirBegin())
			try {
				const response = await axios({
					baseURL: `${url}/accts/${ID.ACCT}/repos/${ID.REPO}/fldrs/${ID.FLDR}/rmv`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'get'
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(removeDirFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					if (response.data.RSP.ACK === true || response.data.RSP.ACK === 'true') {

						let acct = state.getState().acct
						let repo = state.getState().repo

						dispatch(fetchDocuments({ ACCT:acct.ACCT.ID, REPO:repo.activeRepo.ID, FLDR: repo.activeDir.ID, PAGE: repo.activeDirContentsPagination.PAGE, 
							PAGE_SIZE:repo.activeDirContentsPagination.PAGE_SIZE, QUERY:repo.activeDirContentsPagination.QUERY }))
						// let contents = state.getState().repo.activeDirContents
						// if (contents !== undefined ? contents !== null ? contents.length > 0 : false : false) {
						// 	for (let item of contents) {
						// 		if (item.ID === ID.FLDR) {
						// 			contents.splice(contents.indexOf(item), 1)
						// 			dispatch(removeDirSuccess(contents))
						// 			break
						// 		}
						// 	}
						// } else {
						// 	dispatch(removeDirFailure('removeDirectory: Unable to locate the Contents list.'))
						// }
					} else {
						dispatch(removeDirFailure('removeDirectory: The server failed to remove the Directory.'))
					}
				}
			} catch (error) {
				dispatch(removeDirFailure(error))
			}
		}
	}
}


export const removeDocument = (ID) => {
	return async dispatch => {
		if (ID.ACCT !== undefined && ID.REPO !== undefined && ID.FLDR !== undefined && ID.DOC !== undefined) {
			dispatch(removeDocBegin())
			try {
				const response = await axios({
					baseURL: `${url}/accts/${ID.ACCT}/repos/${ID.REPO}/fldrs/${ID.FLDR}/docs/${ID.DOC}/rmv`,
					crossDomain: true,
					timeout: 200000,
					withCredentials: true,
					credentials: 'same-origin',
					responseType: 'json',
					method: 'get'
				})
				if (response.data.ERR !== null) {
					if (validateSessionError(response.data.ERR))
						dispatch(authenticateFailure())
					dispatch(removeDocFailure(response.data.ERR))
				} else if (response.data.RSP !== null) {
					if (response.data.RSP.ACK === true || response.data.RSP.ACK === 'true') {

						let acct = state.getState().acct
						let repo = state.getState().repo
						
						dispatch(fetchDocuments({ ACCT:acct.ACCT.ID, REPO:repo.activeRepo.ID, FLDR: repo.activeDir.ID, PAGE: repo.activeDirContentsPagination.PAGE, 
							PAGE_SIZE:repo.activeDirContentsPagination.PAGE_SIZE, QUERY:repo.activeDirContentsPagination.QUERY }))


						// let contents = state.getState().repo.activeDirContents
						// if (contents !== undefined ? contents !== null ? contents.length > 0 : false : false) {
						// 	for (let item of contents) {
						// 		if (item.ID === ID.DOC) {
						// 			contents.splice(contents.indexOf(item), 1)
						// 			dispatch(removeDocSuccess(contents))
						// 			break
						// 		}
						// 	}
						// } else {
						// 	dispatch(removeDocFailure('removeDocument: Unable to locate the Contents list.'))
						// }
					} else {
						dispatch(removeDocFailure('removeDocument: The server failed to remove the Document.'))
					}
				}
			} catch (error) {
				dispatch(removeDocFailure(error))
			}
		}
	}
}

export const updateSearchList = (contents) => {
	return async dispatch => {
		dispatch(_updateSearchList(contents))
	}
}