import React, { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import { Row,Col,Card,CardHeader,CardBody } from 'shards-react'
import moment from 'moment'
import styled from 'styled-components'

import { fetchAccountUsers,updateAccountUsersPaginationParams } from '../../redux/actions/accountActions'
import AccountReactTable from './AccountReactTable'


const mapStateToProps = (state) => ({
  acct: state.acct.ACCT,
  acct_users: state.acct.ACCT_USERS,
  pagination: state.acct.ACCT_USERS_PAGINATION,
  loading: state.acct.loading
})

const mapDispatchToProps = dispatch => ({
  fetchUsers: (ID) => dispatch(fetchAccountUsers(ID)),
  setPagination: (params) => dispatch(updateAccountUsersPaginationParams(params))
})

export default connect(mapStateToProps,mapDispatchToProps)(({fetchUsers,acct,setPagination,acct_users,loading,pagination}) => {

  useEffect(() => {
    if(acct.ID) {
      if(!acct_users && !loading) {
        fetchUsers({ACCT:acct})
      }else if((acct_users && JSON.stringify(pagination) !== JSON.stringify(acct_users.PAGINATION)) && !loading) {
        fetchUsers({
          ACCT:acct,
          PAGE:pagination.PAGE,
          PAGE_SIZE:pagination.PAGE_SIZE
        })
      }
    }
  },[pagination])

  const pagination_actions = {
    next_page: () => {
      
      setPagination({
        ...pagination,
        PAGE: pagination.PAGE + 1
      })
    },
    previous_page: () => {
      
      setPagination({
        ...pagination,
        PAGE: pagination.PAGE - 1
      })
    },
    go_to_page: (page) => {
      
      setPagination({
        ...pagination,
        PAGE: page
      })
    },
    show_results: (num) => {
      
      setPagination({
        ...pagination,
        PAGE_SIZE: num
      })
    }
  }

  const columns = [{
    Header: "First Name",
    accessor: "FNM",
    maxWidth: 200,
    Cell: row => (<span>{row.row.original.FNM}</span>)
  },
  {
    Header: "Last Name",
    accessor: "LNM",
    maxWidth: 200,
    Cell: row => (<span>{row.row.original.LNM}</span>)
  },
  {
    Header: "Username",
    accessor: "NM",
    maxWidth: 200,
    Cell: row => (<span>{row.row.original.NM}</span>)
  },
  {
    Header: "Email",
    accessor: "EML",
    Cell: row => (<span>{row.row.original.EML}</span>)
  },
  {
    Header: "Phone",
    accessor: "PHN",
    Cell: row => (<span>{row.row.original.PHN}</span>)
  },
  {
    Header: "Last Login",
    accessor: "LLOGN",
    Cell: row => (<span>{row.row.original.LLOGN !== null ? moment(Number(row.row.original.LLOGN)).format("MMM Do YYYY h:mma") : 'not found'}</span>)
  }]

  const Styled_Div = styled.div`

  `

  return (
    <Styled_Div>
      {acct_users && <AccountReactTable
        columns={columns}
        data={acct_users.USRS}
        pagination={pagination}
        pagination_actions={pagination_actions}
        title="Active Users"
      />}
    </Styled_Div>
  )

})