import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { fetchEndpoint, authenticate, initApp } from "./redux/actions/authActions";
import { initAccount } from './redux/actions/accountActions'
import { connect} from 'react-redux'
import { endLoader,initLayoutProps } from './redux/actions/layoutActions'
import { PropTypes } from 'prop-types'
//USED TO AUTHENTICATE/REDIRECT USER TO LOGIN PAGE IF NEEDED
import PrivateRoute from "./PrivateRoute"
import ReactLoading from 'react-loading'
import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

class App extends React.Component {

	componentDidMount() {

		this.props.initApp()

		this.props.getEndpoint()
		
		this.props.auth()

		this.props.initLayout()

		this.props.initAcct()
		
		setTimeout(() => this.props.endLoader(),2000)
	}

	render() {

		if(this.props.timeout === true) {
			return (
				<Router basename={process.env.REACT_APP_BASENAME || ""}>
					<div>
						{routes.map((route, index) => {
							return (
								<PrivateRoute
									key={index}
									path={route.path}
									exact={route.exact}
									auth={this.props.authenticated}
									component={props => {
										return (
											<route.layout {...props}>
												<route.component {...props} />
											</route.layout>
										);
									}}
								/>
							);
						})}

					</div>
				</Router>
			)
		}else if(this.props.timeout === false) {
			return (
				<div class="row">
					<div className="sdx-page-loader">
						<ReactLoading type={'cylon'} color={'blue'} width={'15%'} height={'15%'}  />
					</div>
				</div>
			)
		}
	}
}

App.defaultProps = {
	timeout: false
}

App.propTypes = {
	timeout: PropTypes.bool
}

const mapStateToProps = state => ({
	timeout: state.layout.appAuthTimeout,
	authenticated: state.auth.authenticated
})

const mapDispatchToProps = dispatch => ({
	endLoader: () => dispatch(endLoader()),
	getEndpoint: () => dispatch(fetchEndpoint()),
	auth: () => dispatch(authenticate()),
	initLayout: () => dispatch(initLayoutProps()),
	initAcct: () => dispatch(initAccount()),
	initApp: () => dispatch(initApp())
})

export default connect(mapStateToProps,mapDispatchToProps)(App) 