import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

const EmptyLayout = ({ children, noNavbar, noFooter, noSidebar }) => (
  <Container fluid >
    <Row>
      {/* {noSidebar && <MainSidebar hideLogoText />} */}
      <Col className="main-content" tag="main">
        {noNavbar && <MainNavbar />}
        {children}
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
    <Row>
      {/* moved to push footer across entire bottom of page, was directly */}
      {/* {!noFooter && <MainFooter />} */}
    </Row>
  </Container>
);

EmptyLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
  /**
   * Whether to display the sidebar, or not.
   */
};

EmptyLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
  noSidebar: false
};

export default EmptyLayout;