import React, { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import { Container,Row,Col,ButtonGroup,Button } from 'shards-react'
import PageTitle from "../components/common/PageTitle"
import TemplateFields from '../components/doctype/TemplateFields'
import TemplateSettings from '../components/doctype/TemplateSettings'
import ExpirationSettings from '../components/doctype/ExpirationSettings'
import { resetDocType,fetchDocType,editDocType,fetchDocTypeLU,clearDocTypeLU } from '../redux/actions/docTypeActions'
import { Redirect } from 'react-router-dom'

const EditDocumentType = (props) => {

  const {template,valid,acct,updateDocType,lu,getLU,getTemplate,clearLU} = props
  const [redirect,update] = useState(false)
  

  const [containerClasses,setContainerClasses] = useState('')
  const [imageClasses,setImageClasses] = useState('d-flex justify-content-center update-template-image')

  const imageRef = React.createRef()
  const containerRef = React.createRef()


  useEffect(() => {

    if(template.ID === undefined) {
      update(true)
    }else if(template.ID !== undefined && template.TJT === 'ID') {
      getTemplate({ACCT:acct.ID,DOCTP:template.ID})
    }
  })

  
  return (
    (redirect ?
      <Redirect to="/account" />
      :
      <div>
        <div className={imageClasses}>
          <span className="update-complete-text">Template Updated!</span>
        </div>
        <div className={containerClasses}>
          <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
              <PageTitle title="Edit Document Template" subtitle="Documents" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
              {/* ADD DOC TYPE CONTENT HERE */}
              <Col lg='12'>
                <Col lg='2' className="mb-4">
                  <ButtonGroup className="d-flex my-auto">
                    <Button theme='primary'
                      disabled={!valid}
                      onClick={() => {
                        updateDocType({ACCT: acct.ID, DOCTP: template})
                        setContainerClasses("update-template-background-complete")
                        setImageClasses("d-flex justify-content-center update-template-image complete")
                        setTimeout(() => {
                          setContainerClasses("")
                          setImageClasses("d-flex justify-content-center update-template-image")
                          update(true)
                        },2000)
                      }}>Update Template</Button>
                  </ButtonGroup>
                </Col>
              </Col>
              <Col lg="6">
                <TemplateSettings />
              </Col>
              <Col lg="6">
                <ExpirationSettings />
              </Col>
              <Col lg="12">
                <TemplateFields />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  )
}

EditDocumentType.defaultProps = {
  docTypeId: 0
}

const mapStateToProps = (state) => ({
  usingNew: state.doctype.usingNewDocType,
  lu: state.doctype.DOCTPLU,
  template: state.doctype.DOCTP,
  valid: state.doctype.valid,
  acct: state.acct.ACCT,
  existingLoaded: state.doctype.existingLoaded
})

const mapDispatchToProps = (dispatch) => ({
  getTemplate: (ID) => dispatch(fetchDocType(ID)),
  getLU: (ID) => dispatch(fetchDocTypeLU(ID)),
  updateDocType: (ID) => dispatch(editDocType(ID)),
  reset: () => dispatch(resetDocType()),
  clearLU: () => dispatch(clearDocTypeLU())
})

export default connect(mapStateToProps,mapDispatchToProps)(EditDocumentType)