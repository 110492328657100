import React from 'react'
import { connect } from 'react-redux'
import { 
  Collapse, 
  FormInput, 
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormTextarea,
  Form,
  Button
} from 'shards-react'
import PropTypes from 'prop-types'

import { addNewDirectory,updateNewDir } from '../../redux/actions/repoActions'
import { toggleAddDirCollapse } from '../../redux/actions/layoutActions'

const AddDirectory = (props) => {
  const {fldr,open,updateNewFldr,addDir,toggleDropdown,acct,repo,parentFldr} = props
  return (
    <Collapse
      open={open}
    >
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Add New Folder</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    {/* Folder Title */}
                    <Col md="6" className="form-group">
                      <label htmlFor="feFirstName">Folder Title</label>
                      <FormInput
                        id="fldrName"
                        placeholder="Folder title"
                        defaultValue={fldr.NM}
                        onChange={(e) => {
                          let newfldr = fldr
                          newfldr.NM = e.target.value
                          updateNewFldr(newfldr)
                        }}
                      />
                    </Col>
                    {/* Locked? */}
                    {/* <Col md="6" className="form-group">
                      
                      <FormCheckbox
                        defaultChecked={fldr.LCKD === 'true'}
                        onChange={(e) => {
                          let newfldr = fldr
                          newfldr.LCKD = !fldr.LCKD
                          updateNewFldr(newfldr)
                        }}
                      >Locked?</FormCheckbox>
                    </Col> */}
                  </Row>
                  <Row form>
                    {/* Description */}
                    <Col md="12" className="form-group">
                      <label htmlFor="feDescription">Description</label>
                      <FormTextarea 
                        id="feDescription" 
                        rows="5"
                        placeholder="Folder description"
                        defaultValue={fldr.DESC}
                        onChange={(e) => {
                          let newfldr = fldr
                          newfldr.DESC = e.target.value
                          updateNewFldr(newfldr)
                        }}
                        />
                    </Col>
                  </Row>
                  <Row form>
                    <Button 
                      theme="primary"
                      onClick={() => {
                        let newfldr = fldr
                        newfldr = {
                          ...newfldr,
                          ACCT: {
                            ID: acct.ID
                          },
                          REPO: {
                            ID: repo.ID
                          },
                          FLDR: {
                            ID: parentFldr.ID
                          },
                          USR: {
                            ID: JSON.parse(sessionStorage.getItem('USR')).USR.ID
                          }
                        }
                        addDir({ACCT:acct.ID,REPO:repo.ID,FLDR:parentFldr.ID,DIR:newfldr})
                        toggleDropdown()
                      }}
                    >Add Directory</Button>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Collapse>

  )
}

AddDirectory.defaultProps = {
  fldr: {
    NM: 'Folder Title',
    DESC: 'Folder Description',
    LCKD: true
  },
  open: false
}

AddDirectory.propTypes = {
  fldr: PropTypes.object,
  open: PropTypes.bool
}

const mapStateToProps = state => ({
  fldr: state.repo.newFolder,
  open: state.layout.addFolderOpen,
  acct: state.acct.ACCT,
  repo: state.repo.activeRepo,
  parentFldr: state.repo.activeDir
})

const mapDispatchToProps = dispatch => ({
  addDir: (ID) => dispatch(addNewDirectory(ID)),
  updateNewFldr: (fldr) => dispatch(updateNewDir(fldr)),
  toggleDropdown: () => dispatch(toggleAddDirCollapse())
})

export default connect(mapStateToProps,mapDispatchToProps)(AddDirectory)