import React, { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import { Container,Row,Col,ButtonGroup,Button } from 'shards-react'
import PageTitle from "../components/common/PageTitle"
import TemplateName from '../components/doctype/TemplateName'
import TemplateFields from '../components/doctype/TemplateFields'
import TemplateSettings from '../components/doctype/TemplateSettings'
import ExpirationSettings from '../components/doctype/ExpirationSettings'
import { setNewDocType,resetDocType,addDoctype } from '../redux/actions/docTypeActions'

const AddDocumentType = (props) => {

  const {template,usingNew,setNewTemplate,reset,valid,acct,add} = props

  const [containerClasses,setContainerClasses] = useState('')
  const [imageClasses,setImageClasses] = useState('d-flex justify-content-center update-template-image')

  useEffect(() => {

    // WILL SET THE DEFAULT TEMPLATE STRUCTURE IF 
    // NONE IS SET AND WE WANT TO CREATE A NEW DOCTYPE
    if(!usingNew) {
      setNewTemplate()
    }

    if(template.ID !== undefined) {
      //RESET DOCTP TO ORIGINAL EMPTY OBJ STATUS
      //RESET USINGNEW FLAG TO FALSE
      //THIS ONLY GETS TRIGGERED IF WE SUCCESSFULLY SAVED BEFORE 
      //LEAVING THIS PAGE
      reset()
    }
  })


  return (
    <div>
      <div 
        className={imageClasses}>
        {/* <span className="material-icons update-template">check_circle</span> */}
        <span className="update-complete-text">Template created!</span>
      </div>
      <div className={containerClasses}>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="Add Document Template" subtitle="Documents" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            {/* ADD DOC TYPE CONTENT HERE */}
            <Col lg='12'>
              <Col lg='2' className="mb-4">
                <ButtonGroup className="d-flex my-auto">
                  <Button theme='primary'
                    disabled={!valid}
                    onClick={() => {
                      console.log('template: ', template)
                      add({ACCT:acct.ID,JSON:{...template,ACCT:{ID:acct.ID}}})
                      setContainerClasses("update-template-background-complete")
                      setImageClasses("d-flex justify-content-center update-template-image complete")
                      setTimeout(() => {
                        setContainerClasses("")
                        setImageClasses("d-flex justify-content-center update-template-image")
                      },2000)
                    }}>Add Template</Button>
                  <Button theme='danger'
                    disabled={!valid}
                    onClick={() => {reset()}}>Reset Form</Button>
                </ButtonGroup>
              </Col>
            </Col>
            <Col lg="6">
              <TemplateSettings />
            </Col>
            <Col lg="6">
              <ExpirationSettings />
            </Col>
            <Col lg="12">
              {template.FLD0 !== undefined && <TemplateFields />}
            </Col>
          </Row>
          
        </Container> 
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  usingNew: state.doctype.usingNewDocType,
  template: state.doctype.DOCTP,
  valid: state.doctype.valid,
  acct: state.acct.ACCT
})

const mapDispatchToProps = (dispatch) => ({
  setNewTemplate: () => dispatch(setNewDocType()),
  reset: () => dispatch(resetDocType()),
  add: (ID) => dispatch(addDoctype(ID))
})

export default connect(mapStateToProps,mapDispatchToProps)(AddDocumentType)