import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import filesize from 'filesize'
import styled from 'styled-components'
import { fetchDocuments,updateDocumentsPagination,setActiveDirectory,removeDirectory,removeDocument } from '../../redux/actions/repoActions'
import { setNavbarSearchHandler,setSearchTimer } from '../../redux/actions/layoutActions'
import { setAuthzTimer } from '../../redux/actions/authActions'
import axios from 'axios'
import moment from 'moment'
import { Button,ButtonGroup } from 'shards-react'
import ReactTooltip from 'react-tooltip'
import ReactFileTable from './ReactFileTable'
import url from '../../connection/url'

const Styled_Div = styled.div`

`
const mapStateToProps = state => ({
  contents: state.repo.activeDirContents,
  loading: state.repo.activeDirContentsLoading,
  pagination: state.repo.activeDirContentsPagination,
  error: state.repo.activeDirContentsError,
  activeRepo: state.repo.activeRepo,
  activeDir: state.repo.activeDir,
  acct: state.acct.ACCT,
  toggleTips: state.layout.toggleHideTooltips,
  navbarSearchHandler: state.layout.navbarSearchHandler,
  timer: state.layout.timer
})

const mapDispatchToProps = dispatch => ({
  fetchDocuments: (ID) => dispatch(fetchDocuments(ID)),
  setPagination: (ID) => dispatch(updateDocumentsPagination(ID)),
  setDir: (ID) => dispatch(setActiveDirectory(ID)),
  setHandler: (func) => dispatch(setNavbarSearchHandler(func)),
  setTimer: (func) => dispatch(setSearchTimer(func)),
  removeDir: (ID,cbID) => dispatch(removeDirectory(ID,cbID)),
  removeDoc: (ID) => dispatch(removeDocument(ID)),
  setAuthTimer: () => dispatch(setAuthzTimer())
})

export default connect(mapStateToProps,mapDispatchToProps)
  (({ toggleTips, loading, error, contents, pagination, activeRepo, activeDir, acct, navbarSearchHandler, timer,
    fetchDocuments, setPagination, setDir, setHandler, setTimer, removeDir, removeDoc, setAuthTimer }) => {

  const [searchVal,setSearchVal] = useState()
  
  const valRef = useRef(searchVal)
  valRef.current = searchVal

  const paginationRef = useRef(pagination)
  paginationRef.current = pagination

  useEffect(() => {
    //reset auth timer
    setAuthTimer()
    
    if(acct.ID && activeRepo.ID) {
      if(!activeDir) {
        setDir({ACCT:acct.ID,DIR:activeRepo.FLDR})
      }else if(!contents && !loading && !error) {
        fetchDocuments({ ACCT:acct.ID, REPO:activeRepo.ID, FLDR: activeDir.ID })
      }else if((contents && JSON.stringify(contents.PAGINATION) !== JSON.stringify(pagination)) && !loading) {
        //if the query string is new
        if((pagination.QUERY !== contents.PAGINATION.QUERY)) {
          fetchDocuments({ ACCT:acct.ID, REPO:activeRepo.ID, FLDR: activeDir.ID, PAGE:0, PAGE_SIZE:pagination.PAGE_SIZE, QUERY:pagination.QUERY })
        }else {
          fetchDocuments({ ACCT:acct.ID, REPO:activeRepo.ID, FLDR: activeDir.ID, PAGE:pagination.PAGE, PAGE_SIZE:pagination.PAGE_SIZE, QUERY:pagination.QUERY })
        }
      }
    }
    if(!navbarSearchHandler) {
      setHandler((e) => {
        
        setSearchVal(e.target.value)
        
        let time = setTimeout(() => {
          setPagination({
            ...paginationRef.current,
            QUERY: valRef.current || ''
          })
        },1500)
        setTimer(time)
        
      })
    }
  },[pagination,activeRepo,activeDir])


  const handleDownload = async(row) => {
    if(row.original.TP === "DOC") {

      const link = document.getElementById(`${row.original.TTL}-${row.original.ID}`)

      try {
        let response = await axios({
          baseURL: link.href,
          withCredentials: true,
          method: 'get'
        })
        if(response.status === 200) {
          link.click()
        }else {
          alert("The download is unavailable at this time.")
        }
      }catch(error) {
        console.log('error: ', error)
        alert("The download is unavailable at this time.")
      }
    }
  }

  const handleItemDelete = (row) => {
    if(row.original.TP === 'DOC') {
      removeDoc({ACCT:acct.ID,REPO:activeRepo.ID,FLDR:activeDir.ID,DOC:row.original.ID}, 
        fetchDocuments({ ACCT:acct.ID, REPO:activeRepo.ID, FLDR: activeDir.ID, PAGE:pagination.PAGE, PAGE_SIZE:pagination.PAGE_SIZE, QUERY:pagination.QUERY }))
    }else if(row.original.TP === 'FLDR') {
      removeDir({ACCT:acct.ID,REPO:activeRepo.ID,FLDR:row.original.ID},
         { ACCT:acct.ID, REPO:activeRepo.ID, FLDR: activeDir.ID, PAGE:pagination.PAGE, PAGE_SIZE:pagination.PAGE_SIZE, QUERY:pagination.QUERY })
    }
  }

  const pagination_actions = {
    next_page: () => {
      
      setPagination({
        ...pagination,
        PAGE: pagination.PAGE + 1
      })
    },
    previous_page: () => {
      
      setPagination({
        ...pagination,
        PAGE: pagination.PAGE - 1
      })
    },
    go_to_page: (page) => {
      
      setPagination({
        ...pagination,
        PAGE: page
      })
    },
    show_results: (num) => {
      
      setPagination({
        ...pagination,
        PAGE_SIZE: num
      })
    }
  }


  const tableColumns = [
    {
      Header: "",
      accessor: "icon",
      minWidth: 15,
      maxWidth: 60,
      width: 25,
      sortable: false,
      Cell: ({row}) => (
        <div className="file-manager__item-icon d-flex" tabIndex="0">
          <div className="">
            {row.original.TP === "DOC" ? (
              <i className="material-icons">&#xE24D;</i>
            ) : (
                <i className="material-icons">&#xE2C7;</i>
              )}
          </div>
        </div>
      )
    },
    {
      Header: "Name",
      accessor: "NM",
      minWidth: 200,
      maxWidth: 300,
      width: 300,
      className: "text-left justify-content-start",
      Cell: ({row}) => {
        return (
          <div
            className=""
            style={{ cursor: (row.original.TP === 'FLDR' ? 'pointer' : 'auto') }}
            onClick={() => {
              if (row.original.TP === 'FLDR') {
                setDir({ACCT:acct.ID,DIR:row.original})
              }
            }}>
            {row.original.TP === 'DOC' && <h6 className="file-manager__item-title">{row.original.TTL}</h6>}
            {row.original.TP === 'FLDR' && <h6 className="file-manager__item-title">{row.original.NM}</h6>}
            <span className="file-manager__item-meta">
              {(row.original.TP === 'DOC' && row.original.DOCTP !== undefined) && row.original.DOCTP.NM}
            </span>
          </div>
        )
      }
    },
    {
      Header: "Last Modified",
      accessor: "LU",
      maxWidth: 250,
      Cell: ({row}) => (
        <div>
          <h6>{row.original.LU !== null && moment(Number(row.original.LU)).format("MMM Do YYYY h:mma")}</h6>
        </div>
      )
    },
    {
      Header: "File Size",
      accessor: "SZ",
      maxWidth: 150,
      minWidth: 50,
      width: 100,
      Cell: ({row}) => {
        return (
          <div>
            <h6 className="file-manager__item-title">{row.original.SZ && filesize(Number(row.original.SZ || 0))}</h6>
          </div>
        )
      }
    },
    {
      Header: "Encrypted",
      accessor: "ENCD",
      maxWidth: 125,
      minWidth: 50,
      width: 50,
      className: "text-center d-flex align-items-center",
      Cell: ({row}) => (
        <Button
          theme="">
          <span style={{ color: (row.original.ENCD === 'true' ? 'green' : 'red') }}
            className="material-icons">
            {row.original.ENCD === 'true' ? 'lock' : 'lock_open'}
          </span>
        </Button>
      )
    },
    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 150,
      minWidth: 50,
      width: 100,
      sortable: false,
      className: "justify-content-end",
      Cell: ({row}) => (
        <div>
          <ButtonGroup size="sm" className="ml-auto">
            {/* ENCRYPT FILE BUTTON GROUP */}
            {row.original.TP === 'DOC' && 
              <Button
                data-tip={row.original.TP === 'DOC' ? (row.original.ENCD === 'true' ? 'Unencrypt Document' : 'Encrypt Document') : 'no tip'}
                data-for={row.original.ID + '-enc'}
                theme="white">
                <span className="material-icons">{row.original.ENCD === 'true' ? 'no_encryption' : 'enhanced_encryption'}</span>
              </Button>}
            
            {/* DOWNLOAD FILE/ ENTER DIRECTORY BUTTON GROUP */}
            {row.original.TP === "DOC" ?
              <Button
                tag="a"
                data-tip={row.original.FL === 'true' ? 'Download File' : 'File cannot be found.'}
                data-for={row.original.ID + '-dwnld'}
                disabled={row.original.FL !== 'true'}
                href={`${url}/ui/${acct.ID}/repos/${activeRepo.ID}/fldrs/${activeDir.ID}/docs/${row.original.ID}/dwnld`}
                download={row.original.TTL}
                theme="white" 
                // onClick={() => handleDownload(row.row)}
                >
                <span className="material-icons">{row.original.FL === 'true' ? 'cloud_download' : 'cloud_off'}</span>
              </Button>
              :
              <Button 
                data-tip="View this Folder"
                data-for={row.original.ID + '-dir'}
                theme="white" onClick={() => setDir({ACCT:acct.ID,DIR:row.original})}>
                <span className="material-icons">open_in_new</span>
              </Button>
            }
            
            
            {/* DELETE BUTTON GROUP */}
            <Button
              data-tip={row.original.TP === 'DOC' ? 'Remove Document' : 'Remove Folder and all contents.'}
              data-for={row.original.ID + '-del'}
              theme="danger" onClick={() => {
                if(window.confirm("Are you sure you want to remove this " + (row.original.TP === 'DOC' ? "Document?" : "Folder and its contents?"))) 
                  handleItemDelete(row)
              }}>
              <span className="material-icons">delete</span>
            </Button>
            
            {/* <a
              id={`${row.original.TTL}-${row.original.ID}`}
              href={`${url}/ui/${this.props.acct.ID}/repos/${this.props.repo.ID}/fldrs/${this.props.activeDir.ID}/docs/${row.original.ID}/dwnld`}
              download={row.original.TTL}
              hidden
            ></a> */}
          </ButtonGroup>
          {row.original.TP === 'DOC' && 
          <ReactTooltip
            disable={toggleTips}
            id={row.original.ID + '-enc'} 
          />}
          {/* DOWNLOAD/DIR TOOLTIP */}
          {row.original.TP === "DOC" && 
            <ReactTooltip
              disable={toggleTips}
              id={row.original.ID + '-dwnld'}
            />}
            <ReactTooltip
            disable={toggleTips}
            id={row.original.ID + '-del'}
          />
        </div>
        
      )
    }
  ]

  return (
    <Styled_Div>
      {(contents ? contents.DOCS : false) && <ReactFileTable 
        columns={tableColumns}
        data={contents.DOCS}
        pagination={pagination}
        pagination_actions={pagination_actions}
        title=""
        loading={loading}
      />}
    </Styled_Div>
  )
})