import { repoState } from '../initialState'
import {
  FETCH_REPO_BEGIN,
  FETCH_REPO_SUCCESS,
  FETCH_REPO_FAILURE,

  FETCH_REPO_LIST_BEGIN,
  FETCH_REPO_LIST_SUCCESS,
  FETCH_REPO_LIST_FAILURE,

  FETCH_ROOT_FOLDER_BEGIN,
  FETCH_ROOT_FOLDER_SUCCESS,
  FETCH_ROOT_FOLDER_FAILURE,

  FETCH_SUBFOLDERS_BEGIN,
  FETCH_SUBFOLDERS_SUCCESS,
  FETCH_SUBFOLDERS_FAILURE,

  FETCH_DOCUMENTS_BEGIN,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,

  SET_ACTIVE_DIR_BEGIN,
  SET_ACTIVE_DIR_SUCCESS,
  SET_ACTIVE_DIR_FAILURE,

  SET_ACTIVE_DIR_CONTENTS,

  SET_DIRECTORY_LIST,

  FETCH_ACTIVE_DIR_CONTENTS_BATCH_BEGIN,
  FETCH_ACTIVE_DIR_CONTENTS_BATCH_SUCCESS,
  FETCH_ACTIVE_DIR_CONTENTS_BATCH_FAILURE,

  SET_BATCH_FIRST_LOAD,
  
  FETCH_DOCUMENTS_BATCH_BEGIN,
  FETCH_DOCUMENTS_BATCH_SUCCESS,
  FETCH_DOCUMENTS_BATCH_FAILURE,
  
  ADD_DIR_BEGIN,
  ADD_DIR_SUCCESS,
  ADD_DIR_FAILURE,

  UPDATE_NEW_DIR,

  REMOVE_DIR_BEGIN,
  REMOVE_DIR_SUCCESS,
  REMOVE_DIR_FAILURE,

  REMOVE_DOC_BEGIN,
  REMOVE_DOC_SUCCESS,
  REMOVE_DOC_FAILURE,

  UPDATE_SEARCH_LIST,
  RESET_SEARCH_LIST,
  UPDATE_DOCUMENTS_PAGINATION,
  SAVE_PREVIOUS_PAGINATION

} from '../actions/repoActions'

import { RESET_APP } from '../actions/authActions'

export default (state = repoState, action) => {
  // console.log('repo reducer action: ', action)
  switch (action.type) {
    case FETCH_REPO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_REPO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        activeRepo: action.payload
      }
    case FETCH_REPO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        activeRepo: {}
      }
    case FETCH_REPO_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_REPO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        repos: action.payload
      }
    case FETCH_REPO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case FETCH_ROOT_FOLDER_BEGIN:
      return {
        ...state,
        rootFolderLoading: true,
        error: null
      }
    case FETCH_ROOT_FOLDER_SUCCESS:
      return {
        ...state,
        rootFolderLoading: false,
        error: null,
        activeRepo: {
          ...state.activeRepo,
          FLDR: action.payload
        }
        // activeDir: action.payload
      }
    case FETCH_ROOT_FOLDER_FAILURE:
      return {
        ...state,
        rootFolderLoading: false,
        error: action.payload
      }
    case FETCH_SUBFOLDERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_SUBFOLDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        activeRepo: action.payload
      }
    case FETCH_SUBFOLDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case FETCH_DOCUMENTS_BEGIN:
      return {
        ...state,
        activeDirContentsLoading: true,
        error: null
      }
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        activeDirContents: action.payload,
        activeDirContentsLoading: false,
        error: null,
        // activeRepo: action.payload.REPO,
        // activeDir: action.payload.activeDir
      }
    case FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        activeDirContentsLoading: false,
        error: action.payload
      }
    case FETCH_DOCUMENTS_BATCH_BEGIN:
      return {
        ...state,
        documentsLoading: true,
        documentsError: null
      }
    case FETCH_DOCUMENTS_BATCH_SUCCESS:
      return {
        ...state,
        documentsLoading: false,
        documentsError: null,
        activeRepo: action.payload.activeRepo,
        activeDir: action.payload.activeDir
      }
    case FETCH_DOCUMENTS_BATCH_FAILURE:
      return {
        ...state,
        documentsLoading: false,
        documentsError: action.payload
      }
    case FETCH_ACTIVE_DIR_CONTENTS_BATCH_BEGIN:
      return {
        ...state,
        activeDirContentsError: null,
        activeDirContentsLoading: true
      }
    case FETCH_ACTIVE_DIR_CONTENTS_BATCH_SUCCESS:
      return {
        ...state,
        activeDirContentsLoading: false,
        activeDirContentsError: null,
        activeDirContents: action.payload,
        searchedContents: action.payload
      }
    case FETCH_ACTIVE_DIR_CONTENTS_BATCH_FAILURE:
      return {
        ...state,
        activeDirContentsLoading: false,
        activeDirContentsError: action.payload,
        activeDirContents: undefined
      }
    case SET_BATCH_FIRST_LOAD:
      return {
        ...state,
        activeDirFirstLoading: action.payload
      }
    case SET_ACTIVE_DIR_BEGIN:
      return {
        ...state,
        activeDirLoading: true,
        activeDirContentsLoading: false,
        activeDirContentsError: null,
      }
    case SET_ACTIVE_DIR_SUCCESS:
      return {
        ...state,
        activeDir: action.payload,
        activeDirLoading: false,
        activeDirContentsLoading: false,
        activeDirContents: undefined
      }
    case SET_ACTIVE_DIR_FAILURE:
      return {
        ...state,
        activeDir: {},
        activeDirLoading: false,
        error: action.payload
      }
    case SET_ACTIVE_DIR_CONTENTS:
      return {
        ...state,
        activeDirContents: action.payload,
        searchedContents: action.payload
      }
    case SET_DIRECTORY_LIST:
      return {
        ...state,
        directories: action.payload,
        update: !state.update
      }
    case ADD_DIR_BEGIN:
      return {
        ...state,
        newFolderLoading: true,
        newFolderError: null
      }
    case ADD_DIR_SUCCESS:
      return {
        ...state,
        activeDirContents: action.payload,
        newFolder: {},
        newFolderLoading: false
      }
    case ADD_DIR_FAILURE:
      return {
        ...state,
        newFolderError: action.payload,
        newFolderLoading: false
      }
    case UPDATE_NEW_DIR:
      return {
        ...state,
        newFolder: action.payload
      }
    case REMOVE_DIR_BEGIN:
      return {
        ...state,
        activeDirContentsLoading: true
      }
    case REMOVE_DIR_SUCCESS:
      return {
        ...state,
        activeDirContentsLoading: false,
        activeDirContents: action.payload
      }
    case REMOVE_DIR_FAILURE:
      return {
        ...state,
        activeDirContentsLoading: false,
        error: action.payload
      }
    case REMOVE_DOC_BEGIN:
      return {
        ...state,
        activeDirContentsLoading: true
      }
    case REMOVE_DOC_SUCCESS:
      return {
        ...state,
        activeDirContentsLoading: false,
        activeDirContents: action.payload
      }
    case REMOVE_DOC_FAILURE:
      return {
        ...state,
        activeDirContentsLoading: false,
        error: action.payload
      }
    case RESET_APP:
      return {
        repoState
      }
    case UPDATE_SEARCH_LIST:
      return {
        ...state,
        searchedContents: action.payload
      }
    case RESET_SEARCH_LIST:
      return {
        ...state,
        searchedContents: state.activeDirContents
      }
    case UPDATE_DOCUMENTS_PAGINATION:
      return {
        ...state,
        activeDirContentsPagination: action.payload
      }
    default:
      return {
        ...state
      }
  }
}