import React from 'react'
import { connect } from 'react-redux'
import { Col,Row,Card,CardHeader,Form,CardBody } from 'shards-react'
import { FormInput } from 'shards-react';
import { updateTemplate } from '../../redux/actions/docTypeActions'


const TemplateName = (props) => {

  const {name,update} = props

  return (
    <Card small className="mb-4">
      <CardHeader>
        <h6 className="m-0">Template Name</h6>
      </CardHeader>
      <CardBody>
        <Col>
          <Form>
            <Row form>
              <Col md="12" className="form-group">
                <FormInput
                  id="dtName"
                  placeholder="Template Name"
                  defaultValue={name}
                  onChange={(e) => update({NM: e.target.value})}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </CardBody>
    </Card>
  )
}

TemplateName.defaultProps = {
  name: ''
}

const mapStateToProps = (state) => ({
  name: state.doctype.DOCTP.NM
})

const mapDispatchToProps = (dispatch) => ({
  update: (obj) => dispatch(updateTemplate(obj))
})

export default connect(mapStateToProps,mapDispatchToProps)(TemplateName)