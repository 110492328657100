import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import store from './redux/store'


//CREATED TO OVERRIDE THE DEFAULT ROUTE TO ENABLE AUTHENTICATION AND REDIRECTING TO LOGIN PAGE
//SHOULD THE USER NOT BE LOGGED IN.

const PrivateRoute = ({auth,component: Component, ...rest}) => {
    
    return (
    <Route {...rest} render={(props) => (
        (auth === false ? props.location.pathname !== '/login' ? props.location.pathname !== '/forgot-password' ? !props.location.pathname.includes('passwordreset') : false : false : false)
        ? <Redirect to='/login' />
        : <Component {...props}/>
    )} />
    )
}

// const isAuthenticated = store.getState().auth.USR.ID !== undefined

const isAuthenticated = () => {
    return store.getState().auth.authenticated
}

export  default PrivateRoute