import React, { useEffect, useState } from 'react'
import { Row,Col,Card,CardHeader,CardBody } from 'shards-react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import { fetchAccountDocumentTypes,updateAccountDocTypesPaginationParams } from '../../redux/actions/accountActions'
import { updateTemplate } from '../../redux/actions/docTypeActions'
import AccountReactTable from './AccountReactTable'

const Styled_Div = styled.div`

`

const mapStateToProps = (state) => ({
  acct_templates: state.acct.ACCT_DOCTPS,
  pagination: state.acct.ACCT_DOCTPS_PAGINATION,
  error: state.acct.docTypesError,
  loading: state.acct.docTypesLoading,
  acct: state.acct.ACCT
})

const mapDispatchToProps = (dispatch) => ({
  getTemplateList: (ID) => dispatch(fetchAccountDocumentTypes(ID)),
  update: (obj) => dispatch(updateTemplate(obj)),
  setPagination: (params) => dispatch(updateAccountDocTypesPaginationParams(params))
})

export default connect(mapStateToProps,mapDispatchToProps)(({acct_templates,error,loading,acct,getTemplateList,update,pagination,setPagination}) => {

  const [redirect,setRedirect] = useState(false)

  useEffect(() => {
    if(acct.ID) {
      if(!acct_templates && !error && !loading) {
        getTemplateList({ACCT:acct.ID,TJT:"FULL"})
      }else if(acct_templates && JSON.stringify(acct_templates.PAGINATION) !== JSON.stringify(pagination) && !loading) {
        getTemplateList({
          ACCT:acct.ID,
          PAGE: pagination.PAGE,
          PAGE_SIZE: pagination.PAGE_SIZE
        })
      }
    }
  },[pagination])

  /**
   * actions for pagination buttons
   */
  const pagination_actions = {
    next_page: () => {
      setPagination({
        ...pagination,
        PAGE: pagination.PAGE + 1
      })
    },
    previous_page: () => {
      setPagination({
        ...pagination,
        PAGE: pagination.PAGE - 1
      })
    },
    go_to_page: (num) => {
      setPagination({
        ...pagination,
        PAGE: num
      })
    },
    show_results: (num) => {
      setPagination({
        ...pagination,
        PAGE_SIZE: num
      })
    }
  }

  /**
   * actions for rows
   */
  const row_actions = {
    row_click: (id) => {
      update({ID:id,TJT:'ID'})
      setRedirect(true)
    }
  }

  const columns = [{
    Header: "Name",
    accessor: "NM",
    maxWidth: 200,
    Cell: row => (<span>{row.row.original.NM}</span>)
  },
  {
    Header: "Audit",
    accessor: "AUDIT",
    Cell: row => (<span className="material-icons"
                    style={{color: (row.row.original.AUDIT === 'true' ? 'green' : 'red')}}>
                    {row.row.original.AUDIT === 'true' ? 'check' : 'close'}
                  </span>)
  },
  {
    Header: "Encrypted",
    accessor: "ENCD",
    Cell: row => (<span className="material-icons"
                    style={{color: (row.row.original.ENCD === 'true' ? 'green' : 'red')}}>
                    {row.row.original.ENCD === 'true' ? 'check' : 'close'}
                  </span>)
  },
  {
    Header: "Compressed",
    accessor: "CMP",
    Cell: row => (<span className="material-icons"
                    style={{color: (row.row.original.CMP === 'true' ? 'green' : 'red')}}>
                    {row.row.original.CMP === 'true' ? 'check' : 'close'}
                  </span>)
  },
  {
    Header: "Expires",
    accessor: "EXPS",
    Cell: row => (<span className="material-icons"
                    style={{color: (row.row.original.EXPS === 'true' ? 'green' : 'red')}}>
                    {row.row.original.EXPS === 'true' ? 'check' : 'close'}
                  </span>)
  },
  {
    Header: "Expiration Date",
    accessor: "EXPSON",
    Cell: row => (row.row.original.EXPSON && moment(Number(row.row.original.EXPSON)).format("MMM Do YYYY"))
  },
  {
    Header: "Expiration Lifespan",
    accessor: "EXPSAFTR",
    Cell: row => (<span>{row.row.original.EXPSAFTR !== null && row.row.original.EXPSAFTR.YR + ' Yrs ' 
    + row.row.original.EXPSAFTR.MNTH + ' Mths ' + row.row.original.EXPSAFTR.DAY + ' Days'}</span>)
  }]

  return (
    (redirect ?
      <Redirect to="/edit-template" />
    :
      <Styled_Div>
        {acct_templates && <AccountReactTable
          columns={columns}
          data={acct_templates.DOCTPS}
          pagination={pagination}
          pagination_actions={pagination_actions}
          row_actions={row_actions}
          clickable={true}
          title="Available Document Types"
        />}
      </Styled_Div>
    )
  )
})