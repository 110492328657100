
/**
 * THE INITIAL DEFINITION OF THE REDUX STATE OBJECT(S) FOR AUTHENTICATION AND USER ACTIONS
 */
const authState = {

    //LOGIN MARKERS, ERRORS OR LOADING TRIGGERS
    AUTH: {
        loading: false,
        error: null,
        eml: '',
        pass: '',
        success: false
    },
    //USER OBJECT
    USR: {},
    authenticated: false,
    updating: false,
    //ENDPOINT OBJECT
    EP: {},
    epError: null
}

const acctState = {
    /**
     * ACCOUNT CURRENTLY IN USE
     */
    ACCT: {},
    loading: false,
    error: null,
    update: null,
    /**
     * DOCTYPES OF THIS ACCOUNT
     */
    ACCT_DOCTPS: undefined,
    ACCT_DOCTPS_PAGINATION: undefined,
    docTypesLoading: false,
    docTypesError: null,
    /**
     * ACCOUNT USERS
     */
    ACCT_USERS: undefined,
    ACCT_USERS_PAGINATION: undefined,
}

const layoutState = {
    sidebarItems: null,
    /**
     * CONTROLS IF THE USER DROPDOWN IN THE NAVBAR IS OPEN OR CLOSED
     */
    navbarUserVisible: false,
    /**
     * USED TO CONTROL THE TIMEOUT LOADER TO PROPERLY LOAD CORRECT PAGE ON REFRESH OF SITE
     */
    appAuthTimeout: false,

    repoDropdown: false,
    /**
     * USING THIS VARIABLE TO FORCE THE 'DUMB' COMPONENT(S) TO UPDATE WITHOUT INJECTING THE ENTIRE SIDBAR LIST OR WHATEVER SUBSEQUENT OBJECTS 
     * MAY FOLLOW IN THIS 'STATE'
     */
    upate: null,
    /**
     * TRIGGER TO SHOW NOTIFICATION DROPDOWN WHENEVER A NEW NOTIFICATION IS ADDED
     */
    showNotifications: false,
    /**
     * TRIGGER TO OPEN THE COLLAPSABLE CARD FOR ADDING A NEW DIRECTORY
     */
    addFolderOpen: false,
    /**
     * TRIGGER TO SHOW OR HIDE TOOLTIPS ACROSS SITE
     */
    toggleHideTooltips: false,
    /**
     * navbarSearchHandler is a holder to allow other components to use the main navbar search as this need.
     */
    navbarSearchHandler: null
}

const repoState = {
    //active repository
    activeRepo: {},
    //list of repositories and loading/error objects
    repos: undefined,
    loading: false,
    rootFolderLoading: false,
    activeDirLoading: false,
    error: null,
    //list containing the directory tree
    directories: [],
    //Folders loading/error properties
    foldersLoading: false,
    foldersError: null,
    //Documents loading/error properties
    documentsLoading: false,
    documentsError: null,
    //The active Directory to display subfolders, and get files
    activeDir: undefined,
    //The list of documents pulled down through batching and loading/error objects
    activeDirContents: undefined,
    activeDirContentsPagination: undefined,
    activeDirContentsError: null,
    activeDirFirstLoading: true,
    activeDirContentsLoading: false,
    activeDirContentsDone: false,
    update: false,

    //Adding a Folder
    newFolder: {},
    newFolderError: null,
    newFolderLoading: false

}

const uploadState = {
    //array of file/upload objects ready to be configured before uploading
    fileJobs: [],
    //array of upload objects ready to begin uploading
    uploadJobs: [],
    uploadState: "",
    loading: false,
    error: null,
    activeDocType: null
}

const docTypeState = {
    DOCTP: {},
    DOCTPLU: null,
    EDITDOCTP: {},
    usingNewDocType: false,
    existingLoaded: false,
    DOCTP_template: {
        NM: '',
        AUDIT: false,
        ENCD: false,
        CMP: false,
        EXPS: false,
        EXPSON: null,
        EXPSAFTR: null,
        FLD0: {
            NM: '',
            RQD: false,
            SLNS: null
        },
        FLD1: {
            NM: '',
            RQD: false,
            SLNS: null
        },
        FLD2: {
            NM: '',
            RQD: false,
            SLNS: null
        },
        FLD3: {
            NM: '',
            RQD: false,
            SLNS: null
        },
        FLD4: {
            NM: '',
            RQD: false,
            SLNS: null
        },
        FLD5: {
            NM: '',
            RQD: false
        },
        FLD6: {
            NM: '',
            RQD: false
        },
        FLD7: {
            NM: '',
            RQD: false
        },
        FLD8: {
            NM: '',
            RQD: false
        },
        FLD9: {
            NM: '',
            RQD: false
        }
    },
    valid: false,
    loading: false,
    error: null
}

export {
    authState,
    acctState,
    layoutState,
    repoState,
    uploadState,
    docTypeState
}