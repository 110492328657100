
import { authState,acctState,layoutState,repoState,uploadState,docTypeState } from "./initialState"



import {
    INIT_SIDEBAR_ITEMS, 
    TOGGLE_SIDEBAR_DROPDOWN,
    TOGGLE_NAVBAR_USER_DROPDOWN,
    TOGGLE_REPO_DROPDOWN,
    END_APP_LOADER,
    TOGGLE_NOTIFICATION_DROPDOWN,
    TOGGLE_ADD_DIR_COLLAPSE,
    TOGGLE_HIDE_TOOLTIPS,
    SET_NAVBAR_SEARCH_HANDLER,
    SET_SEARCH_TIMER
} from './actions/layoutActions'

import {
    ADD_UPL_JOBS,

    ADD_FILE_JOBS,
    UPDATE_FILE_JOBS,

    ADD_UPL_BEGIN,
    ADD_UPL_SUCCESS,
    ADD_UPL_FAILURE,

    ADD_GLOB_BEGIN,
    ADD_GLOB_SUCCESS,
    ADD_GLOB_FAILURE,

    COMMIT_UPL_BEGIN,
    COMMIT_UPL_SUCCESS,
    COMMIT_UPL_FAILURE,

    ADD_DOC_SUCCESS,
    ADD_DOC_BEGIN,
    ADD_DOC_FAILURE,

    SET_ACTIVE_DOCTYPE_BEGIN,
    SET_ACTIVE_DOCTYPE_SUCCESS,
    SET_ACTIVE_DOCTYPE_FAILURE,
    CLEAR_ACTIVE_UPL_DOCTYPE,
    UPDATE_ACTIVE_UPLOAD_DOC
    
} from './actions/uploadActions'




const layout = (state=layoutState, action) => {
    switch(action.type) {
        case INIT_SIDEBAR_ITEMS: 
            return {
                ...state,
                update: !state.update,
                sidebarItems: action.payload
            }
        case TOGGLE_SIDEBAR_DROPDOWN:
            return {
                ...state,
                update: !state.update,
                sidebarItems: action.payload
            }
        case TOGGLE_NAVBAR_USER_DROPDOWN:
            return {
                ...state,
                navbarUserVisible: !state.navbarUserVisible
            }
        case END_APP_LOADER:
            return {
                ...state,
                appAuthTimeout: true
            }
        case TOGGLE_REPO_DROPDOWN: 
            return {
                ...state,
                repoDropdown: !state.repoDropdown
            }
        case TOGGLE_NOTIFICATION_DROPDOWN:
            return {
                ...state,
                showNotifications: !state.showNotifications
            }
        case TOGGLE_ADD_DIR_COLLAPSE:
            return {
                ...state,
                addFolderOpen: !state.addFolderOpen
            }
        case TOGGLE_HIDE_TOOLTIPS:
            return {
                ...state,
                toggleHideTooltips: !state.toggleHideTooltips
            }
        case SET_NAVBAR_SEARCH_HANDLER:
            return {
                ...state,
                navbarSearchHandler: action.payload
            }
        case SET_SEARCH_TIMER:
            return {
                ...state,
                timer: action.payload
            }
        default:
            return {
                ...state
            }
    }
} 



const upload = (state=uploadState, action) => {
    // console.log('upload actions: ', action)
    switch(action.type) {
        case ADD_FILE_JOBS:
            return {
                ...state,
                fileJobs: action.payload,
                loading: false,
                error: null
            }
        case UPDATE_FILE_JOBS:
            return {
                ...state,
                fileJobs: action.payload
            }
        case ADD_UPL_JOBS: 
            return {
                ...state,
                uploadJobs: action.payload,
                loading: false,
                error: null
            }
        case ADD_UPL_BEGIN: 
            return {
                ...state,
                uploadJobs: action.payload,
                loading: true,
                uploadState: "add-upload",
                error: null
            }
        case ADD_UPL_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadJobs: action.payload,
                error: null,
                uploadState: "add-glob"
            }
        case ADD_UPL_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload,
                uploadState: "error"
            }
        case ADD_GLOB_BEGIN:
            return {
                ...state,
                loading: true,
                uploadState: "await-glob",
                error: null,
                uploadJobs: action.payload
            }
        case ADD_GLOB_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                uploadState: "add-glob",
                uploadJobs: action.payload
            }
        case ADD_GLOB_FAILURE: 
            return {
                ...state,
                loading: false,
                uploadState: "error",
                error: action.payload
            }
        case COMMIT_UPL_BEGIN:
            return {
                ...state,
                loading: true,
                uploadState: "commit-upload",
                error: null
            }
        case COMMIT_UPL_SUCCESS: 
            return {
                ...state,
                loading: false,
                uploadState: "",
                error: null,
                uploadJobs: action.payload
            }
        case COMMIT_UPL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_DOC_BEGIN:
            return {
                ...state,
                uploadJobs: action.payload,
                loading: true,
                error: null
            }
        case ADD_DOC_SUCCESS:
            return {
                ...state,
                uploadJobs: action.payload,
                loading: false
            }
        case ADD_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case SET_ACTIVE_DOCTYPE_BEGIN:
            return {
                ...state
            }
        case SET_ACTIVE_DOCTYPE_SUCCESS:
            return {
                ...state,
                activeDocType: action.payload
            }
        case SET_ACTIVE_DOCTYPE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ACTIVE_UPL_DOCTYPE:
            return {
                ...state,
                activeDocType: null
            }
        case UPDATE_ACTIVE_UPLOAD_DOC:
            return {
                ...state,
                fileJobs: Object.assign([state.fileJobs], {[0]: action.payload})
            }
        default:
            return {
                ...state
            }
    }
}


export { layout,upload }