import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Row, Col, Card, CardFooter, DropdownToggle, DropdownItem, Collapse, FormGroup, FormSelect } from "shards-react";

import { connect } from 'react-redux'

import { setActiveDirSuccess,setActiveRepo } from '../../redux/actions/repoActions'

import { toggleRepoDropdown } from '../../redux/actions/layoutActions'

class DirectoriesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      open: false
    };

    this.handleDirectoryClick = this.handleDirectoryClick.bind(this);
  }

  /**
   * Changes the selected state for a specific (clicked) directory.
   */
  handleDirectoryClick(dir) {
    const newState = { ...this.state };
    this.setState({
      ...(newState.directories[newState.directories.indexOf(dir)] = {
        ...dir,
        selected: !dir.selected
      })
    });
  }

  render() {
    const { fldrs,repos } = this.props;

    const classes = classNames(
      // "file-manager__item--selected",
      "file-manager__item",
      "file-manager__item--directory",
      "mb-3"
    );
    return (

      <Row>
        <Col>
          <FormGroup className="">
            <FormSelect className="w-50" >
              {repos.map((repo,idx) => {
                return (
                  <option onSelect={(e) => {}} key={idx}>{repo.NM}</option>
                )
              })}
            </FormSelect>
            {/* <FormFeedback>Please select your state</FormFeedback> */}
          </FormGroup>
          </Col>
      </Row>
    )

    // if(fldrs !== undefined ? (fldrs !== null ? fldrs.length > 0 : false) : false) {
    //   return (
    //     <Row>
    //       {fldrs.map((dir, idx) => {
    //         const classes = classNames(
    //           dir.selected && "file-manager__item--selected",
    //           "file-manager__item",
    //           "file-manager__item--directory",
    //           "mb-3"
    //         );
  
    //         return (
    //           <Col lg="3" key={idx}>
    //             <Card
    //               onClick={() => this.handleDirectoryClick(dir)}
    //               onDoubleClick={() => this.props.setDir(dir)}
    //               small
    //               className={classes}
    //             >
    //               <CardFooter>
    //                 <span className="file-manager__item-icon">
    //                   <i className="material-icons">&#xE2C7;</i>{" "}
    //                 </span>
    //                 <h6 className="file-manager__item-title">{dir.NM}</h6>
    //               </CardFooter>
    //             </Card>
    //           </Col>
    //         );
    //       })}
    //     </Row>
    //   )
    // }
    // else {
    //   const classes = classNames(
    //     // "file-manager__item--selected",
    //     "file-manager__item",
    //     "file-manager__item--directory",
    //     "mb-3"
    //   );
    //   return (

    //     <Row>
    //       <Col>
    //         <FormGroup className="">
    //           <FormSelect >
    //             {repos.map((repo,idx) => {
    //               return (
    //                 <option onSelect={(e) => {}} key={idx}>{repo.NM}</option>
    //               )
    //             })}
    //           </FormSelect>
    //           {/* <FormFeedback>Please select your state</FormFeedback> */}
    //         </FormGroup>
    //         </Col>
    //     </Row>
    //   )
    // } 
  }
}
DirectoriesList.propTypes = {
  /**
   * The directories array.
   */
  directories: PropTypes.array
};

DirectoriesList.defaultProps = {
  directories: [
    {
      id: 1,
      title: "Projects",
      selected: true
    },
    {
      id: 2,
      title: "Movies",
      selected: false
    },
    {
      id: 3,
      title: "Backups",
      selected: false
    },
    {
      id: 4,
      title: "Photos",
      selected: false
    },
    {
      id: 5,
      title: "Old Files",
      selected: false
    },
    {
      id: 6,
      title: "New Folder with Extremely Long Title",
      selected: false
    }
  ]
};

const mapStateToProps = state => ({
  activeDir: state.repo.activeDir,
  fldrs: state.repo.activeDir.FLDRS,
  open: state.layout.repoDropdown,
  repos: state.repo.repos,
  activeRepo: state.repo.activeRepo
})

const mapDispatchToProps = dispatch => ({
  setDir: (fldr) => dispatch(setActiveDirSuccess(fldr)),
  setRepo: (repo) => dispatch(setActiveRepo(repo)),
  toggle: () => dispatch(toggleRepoDropdown())
})

export default connect(mapStateToProps,mapDispatchToProps)(DirectoriesList);
