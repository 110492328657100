import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
  FormCheckbox
} from "shards-react";

import { PropTypes } from 'prop-types';
import { connect } from 'react-redux'
import { toggleNavbarUserDropdown, toggleTooltips } from './../../../../redux/actions/layoutActions';
import { logout } from '../../../../redux/actions/authActions'


const UserActions = (userDetails) => {

  /**
   * creates a reference to this component's DOM 
   */
  let navRef = React.createRef()
  
  /**
   * functional component lifecycle callback. adding an
   * eventlistener in order to close the dropdown whenever
   * a user clicks outside of said dropdown
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    /**
     * cleanup method to remove the listener whenever the component
     * is unmounted
     */
    return () => {
      document.removeEventListener('mousedown', handleClick, false)
    }

  })

  const handleClick = (e) => {
    if(!navRef.current.contains(e.target) && userDetails.visible)
      userDetails.toggle()
  }

  return (
    <div ref={navRef} >
      <NavItem 
      tag={Dropdown} 
      caret 
      toggle={userDetails.toggle}>
        <DropdownToggle 
        style={{cursor: 'pointer'}}
        caret tag={NavLink} 
        className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/no_img_male.png")}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{userDetails.name}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={userDetails.visible}>
          <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <div className="dropdown-item">
            <FormCheckbox toggle small 
            defaultChecked={userDetails.tooltips}
            onClick={() => userDetails.toggleTips()}
            >
              Hide Tooltips
            </FormCheckbox>
          </div>
          <DropdownItem divider />
          <DropdownItem onClick={() => userDetails.logout()}
          tag={Link} to="/login" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    </div>
  )
}

UserActions.propTypes = {
  visible: PropTypes.bool,
  toggle: PropTypes.func,
  name: PropTypes.string
}

UserActions.defaultProps = {
  visible: false,
  toggle: () => {},
  name: ''
}

const mapStateToProps = state => ({
  visible: state.layout.navbarUserVisible,
  name: state.auth.USR.FNM + ' ' + state.auth.USR.LNM,
  tooltips: state.layout.toggleHideTooltips
})

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleNavbarUserDropdown()),
  toggleTips: () => dispatch(toggleTooltips()),
  logout: () => dispatch(logout())
})

export default connect(mapStateToProps,mapDispatchToProps)(UserActions)