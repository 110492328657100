import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import UploadNotification from './UploadNotification'


export const UploadItems = (props) => (
    <div>
        {props.uploadJobs.map((job, idx) => (
            <UploadNotification key={idx} uploadJob={job} />
        ))}
    </div>
)



UploadItems.propTypes = {
    uploadJobs: PropTypes.array
}

UploadItems.defaultProps = {
    uploadJobs: []
}


const mapStateToProps = state => ({
    uploadJobs: state.upload.uploadJobs,
    uploadState: state.upload.uploadState,
    loading: state.upload.loading
})

export default connect(mapStateToProps)(UploadItems)