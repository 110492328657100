import { createStore, applyMiddleware } from "redux"
import rootReducer from "./rootReducer"
import thunk from 'redux-thunk'
import throttle from 'lodash.throttle'

const saveState = (state) => {
	const serializedState = JSON.stringify(state)
	sessionStorage.setItem('smpldocs--state', serializedState)
}

const store = createStore(
	rootReducer,
	applyMiddleware(thunk)
)

store.subscribe(throttle(() => {
	saveState(store.getState())
},3000))

export default store