import React from "react";
import ReactTable from "react-table-6";
import FuzzySearch from "fuzzy-search";
import ReactLoading from 'react-loading'
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  FormSelect,
  Card,
  CardHeader,
  CardBody
} from "shards-react";

import {
  fetchRepo,
  fetchRepoList,
  fetchRootFolder,
  fetchSubfolders,
  fetchDocuments,
  fetchFolderContentsBatched,
  setActiveDirectory,
  setActiveRepo,
  setActiveDirContents,
  setDirectories,
  removeDirectory,
  removeDocument,
  updateSearchList,
  resetSearchList
} from '../redux/actions/repoActions'

import ReactTooltip from 'react-tooltip'
import { toggleAddDirCollapse,setNavbarSearchHandler } from './../redux/actions/layoutActions';
import { fetchAccount } from '../redux/actions/accountActions'

import axios from 'axios'

import SectionTitle from "../components/file-manager-cards/SectionTitle";
import DirectoriesBreadcrumbs from "../components/file-manager-cards/DirectoriesBreadcrumbs"
import ReposList from "../components/file-manager-cards/ReposList"
import Repos from '../components/folder-contents/RepositoryList'
import AddDirectory from "../components/file-manager-cards/AddDirectory"
import AddUploads from  '../components/file-manager-cards/AddUploads'
import DocumentTypeFields from '../components/file-manager-list/DocumentTypeFields'

import PageTitle from "../components/common/PageTitle";
import FileDropzone from "../components/file-manager-list/FileDropzone";

import url from "../connection/url"

import moment from 'moment'

import filesize from 'filesize'

import { connect } from 'react-redux'




class FileManagerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      currentIdx: 0,
      firstFetch: true,
      activeDirDocSize: 0,
      tableData: [],
      rootFldrCall: false,
      activeDirCall: false
    };

    this.searcher = null;

    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentDidMount() {

    if(this.props.acct.ID) {
      this.props.getRepo({ACCT: this.props.acct.ID, REPO: this.props.acct.REPO.ID})
    }else {
      this.props.getAccount()
    }


    // Initialize the fuzzy searcher.
    this.searcher = new FuzzySearch(
      this.props.contents,
      ["NM"],
      { caseSensitive: false }
    )

    //CREATING THE ONCHANGE METHOD FOR THE NAVBAR SEARCH INPUT
    this.props.setSearchHandler((e) => {
      let searched = this.searcher.search(e.target.value)
      this.props.search(searched)
    })
  }

  componentWillUnmount() {
    this.props.resetSearch()
  }

  componentDidUpdate(prevProps,prevState) {
    if(this.props.acct.ID && !this.props.repo.ID) {
      this.props.getRepo({ACCT: this.props.acct.ID, REPO: this.props.acct.REPO.ID})
      this.props.getRepoList({ACCT: this.props.acct.ID})
    }
    //GET FULL ROOT DIR
    else if((this.props.repo.ID && this.props.repo.FLDR && this.props.repo.FLDR.TJT === 'ID') && !this.props.rootFolderLoading) {
      this.props.getRootFolder({ACCT:this.props.acct.ID,REPO:this.props.repo.ID,FLDR:this.props.repo.FLDR.ID})
    //SET ROOT DIR TO ACTIVE DIR IF NO ACTIVE DIR IS PRESENT
    }else if((this.props.repo.ID && this.props.repo.FLDR && this.props.repo.FLDR.TJT !== 'ID' && !this.props.activeDir) && !this.props.activeDirLoading) {
      this.props.setDir({ACCT:this.props.acct.ID,DIR:this.props.repo.FLDR})
    }
    //SET THE CONTENTS FOR THE ACTIVEDIR DISPLAY
    else if((this.props.activeDir.ID && this.props.activeDir.CNT) ? this.props.contents !== undefined ? !this.props.docsLoading ? this.props.contents.length === 0 ? Number(this.props.activeDir.CNT) !== 0 : false : false : false : false) {
      this.props.setContents(new Array(Number(this.props.activeDir.CNT)).fill({}))
      this.setState({currentIdx:0})
    }else if(Number(prevProps.activeDir.CNT) > 0 && Number(this.props.activeDir.CNT) === 0) {
      this.props.setContents(new Array(Number(this.props.activeDir.CNT)).fill({}))
      this.setState({currentIdx:0})
      this.searcher = new FuzzySearch(
        this.props.contents,
        ["NM","TTL"],
        { caseSensitive: false }
      )
    }else if(this.props.contents !== undefined ? this.props.contents.length > 0 : false) {
      //CREATE NEW SEARCHER IF CONTENTS ARE NEW
      if(prevProps.activeDir.ID !== this.props.activeDir.ID) {
        this.searcher = new FuzzySearch(
          this.props.contents,
          ["NM","TTL"],
          { caseSensitive: false }
        )
      }
      let {currentIdx} = this.state
      if(currentIdx < this.props.contents.length && !this.props.docsLoading && !this.props.docsDone) {
        //BATCH BY EVERY 500 RECORDS
        let increment = 500
        // let endIdx = currentIdx+increment < this.props.contents.length ? currentIdx+increment : this.props.contents.length-1
        let endIdx = (increment + currentIdx) < this.props.contents.length ? (increment + currentIdx) : this.props.contents.length - 1
        //get next batch of folder contents
        this.props.getFolderContents({ACCT: this.props.acct.ID,REPO:this.props.repo.ID,FLDR:this.props.activeDir.ID,TJT:'UI',BEGIN:currentIdx,END:endIdx})
        //UPDATE CURRENTIDX
        //set to last grabbed idx
        currentIdx = endIdx
        //increment 1 in order to move to next beginning index
        currentIdx++
        this.setState({currentIdx})
      }
    }
  }
  /**
   * Method for deleting items.
   */
  handleItemDelete(row) {
    if(row.original.TP === 'DOC') {
      this.props.removeDoc({ACCT:this.props.acct.ID,REPO:this.props.repo.ID,FLDR:this.props.activeDir.ID,DOC:row.original.ID})
    }else if(row.original.TP === 'FLDR') {
      this.props.removeDir({ACCT:this.props.acct.ID,REPO:this.props.repo.ID,FLDR:row.original.ID})
    }
  }

  /**
   * Method for changing main active Directory (Folder)
   * 
   * @param {Object} row 
   */
  handleNewDir(row) {
    if(row.original.TP === 'FLDR')
      // this.props.SetBreadcrumbDirs(this.props.activeDir)
      this.props.setDir({ACCT:this.props.acct.ID,REPO:this.props.repo.ID,DIR:{...row.original,TJT:'ID'}})
      this.setState({
        currentIdx: 0,
        firstFetch: false
      })
  }

  async handleDownload(row) {
    if(row.original.TP === "DOC") {

      const link = document.getElementById(`${row.original.TTL}-${row.original.ID}`)

      try {
        let response = await axios({
          baseURL: link.href,
          withCredentials: true,
          method: 'get'
        })
        if(response.status === 200) {
          link.click()
        }else {
          alert("The download is unavailable at this time.")
        }
      }catch(error) {
        console.log('error: ', error)
        alert("The download is unavailable at this time.")
      }
    }
  }

  /**
   * Changes the page size.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    let searched = this.searcher.search(e.target.value)
    this.props.search(searched)
  }

  render() {
    const { pageSize,pageSizeOptions } = this.state
    const { searchContents,toggleTips } = this.props

    const tableColumns = [
      {
        Header: "",
        accessor: "icon",
        maxWidth: 60,
        sortable: false,
        Cell: row => (
          <div className="file-manager__item-icon" tabIndex="0">
            <div>
              {row.original.TP === "DOC" ? (
                <i className="material-icons">&#xE24D;</i>
              ) : (
                <i className="material-icons">&#xE2C7;</i>
              )}
            </div>
          </div>
        )
      },
      {
        Header: "Name",
        accessor: "NM",
        className: "text-left justify-content-start",
        Cell: row => { 
          return (
          <div
            style={{cursor: (row.original.TP === 'FLDR' ? 'pointer' : 'auto')}}
            onClick={() => { 
            if(row.original.TP === 'FLDR')
              this.handleNewDir(row)
          }}>
            {
              row.original.TP === 'DOC'
              ?
              <h5 className="file-manager__item-title">{row.original.TTL}</h5>
              :
              <h4 className="file-manager__item-title">{row.original.NM}</h4>
            }
            <span className="file-manager__item-meta">
              {(row.original.TP === 'DOC' && row.original.DOCTP !== undefined) && row.original.DOCTP.NM}
            </span>
          </div>
        )}
      },
      {
        Header: "Last Modified",
        accessor: "LU",
        maxWidth: 250,
        Cell: row => (
          <div>
            <h6>{row.original.LU !== undefined ? row.original.LU !== null ? 
              moment(Number(row.original.LU)).format("MMM Do YYYY h:mma") : "No date found" : "No date found"}</h6>
          </div>
        )
      },
      {
        Header: "File Size",
        accessor: "SZ",
        maxWidth: 200,
        Cell: row => {
          return (
            <div>
              <h5 className="file-manager__item-title">{row.original.TP == 'DOC' ? row.original.SZ !== null ? 
              filesize(Number(row.original.SZ)) : "No Size found" : ""}</h5>
            </div>
          )
        }
      },
      {
        Header: "Encrypted",
        accessor: "ENCD",
        maxWidth: 100,
        className: "text-center d-flex align-items-center",
        Cell: row => (
          <Button 
            theme="">
            <span style={{color: (row.original.ENCD === 'true' ? 'green' : 'red')}}
              className="material-icons">
              {row.original.ENCD === 'true' ? 'lock' : 'lock_open'}
            </span>
          </Button>
        )
      },
      {
        Header: "Actions",
        accessor: "actions",
        maxWidth: 200,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table ml-auto">
            {/* ENCRYPT FILE BUTTON GROUP */}
            {row.original.TP === 'DOC'
            ?
            <Button
              data-tip={row.original.TP === 'DOC' ? (row.original.ENCD === 'true' ? 'Unencrypt Document' : 'Encrypt Document') : 'no tip'}
              data-for={row.original.ID + '-enc'} 
              theme="white">
              <span className="material-icons">{row.original.ENCD === 'true' ? 'no_encryption' : 'enhanced_encryption'}</span>
            </Button>
            :
            ''
            }
            {row.original.TP === 'DOC'
            ?
            <ReactTooltip 
            disable={toggleTips}
            id={row.original.ID + '-enc'} />
            :
            ''}
            {/* DOWNLOAD FILE/ ENTER DIRECTORY BUTTON GROUP */}
            {row.original.TP === "DOC" ?
            <Button 
              data-tip={row.original.FL === 'true' ? 'Download File' : 'File cannot be found.'}
              data-for={row.original.ID + '-dwnld'}
              disabled={row.original.FL !== 'true'} 
              theme="white" onClick={() => this.handleDownload(row)}>
              <span className="material-icons">{row.original.FL === 'true' ? 'cloud_download' : 'cloud_off'}</span>
            </Button>
            :
            <Button 
              data-tip="View this Folder"
              data-for={row.original.ID + '-dir'}
              theme="white" onClick={() => this.handleNewDir(row)}>
              <span className="material-icons">open_in_new</span>
            </Button>
            }
            {/* DOWNLOAD/DIR TOOLTIP */}
            <ReactTooltip 
              disable={toggleTips}
              id={row.original.ID + '-dir'}
            />
            <ReactTooltip 
              disable={toggleTips}
              id={row.original.ID + '-dwnld'}
            />
            {/* DELETE BUTTON GROUP */}
            <Button
              data-tip={row.original.TP === 'DOC' ? 'Remove Document' : 'Remove Folder and all contents.'}
              data-for={row.original.ID + '-del'}
              theme="danger" onClick={() => this.handleItemDelete(row)}>
              <span className="material-icons">delete</span>
            </Button>
            <ReactTooltip 
              disable={toggleTips}
              id={row.original.ID + '-del'}
            />
            <a 
              id={`${row.original.TTL}-${row.original.ID}`}
              href={`${url}/ui/${this.props.acct.ID}/repos/${this.props.repo.ID}/fldrs/${this.props.activeDir.ID}/docs/${row.original.ID}/dwnld`}
              download={row.original.TTL}
              hidden
            ></a>
          </ButtonGroup>
        )
      }
    ];

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="File Manager" subtitle="Dashboards" className="text-sm-left mb-3" />
          {/* BUTTONS TO SWITCH BETWEEN LIST AND CARD VIEW --CARD VIEW IS NOT SETUP */}
          {/* <Col className="d-flex">
            <ButtonGroup size="sm" className="d-inline-flex my-auto ml-auto mr-auto mr-sm-0">
              <Button theme="white" tag={NavLink} to="/file-manager-list">
                <i className="material-icons">&#xE8EF;</i>
              </Button>
              <Button theme="white" tag={NavLink} to="/file-manager-cards">
                <i className="material-icons">&#xE8F0;</i>
              </Button>
            </ButtonGroup>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <div className="file-manager file-manager-cards" >
              {/* Directories */}
              <SectionTitle title="Repositories" />
              {/* <DirectoriesList /> */}
              <Repos />
            </div>

            <div className="file-manager file-manager-cards">
              <SectionTitle title="Directories" />
              {/* Breadcrumb list for Directories */}
              <DirectoriesBreadcrumbs />
            </div>
            <div className="file-manager file-manager-cards" >
              <AddDirectory />
            </div>
            <Row className="py-4 no-gutters">
              <AddUploads />
            </Row>
          </Col>
          <Col>
          <div className="file-manager file-manager-cards" >
            {(this.props.activeDocType !== undefined && this.props.activeDocType !== null) && <SectionTitle title="Upload Settings" />}
            {/* set doctype fields */}
            <DocumentTypeFields />
          </div>
            
          </Col>
        </Row>
        
        {this.props.docsLoading && this.props.firstLoading
        ?
        <div class="row">
					<div className="sdx-page-loader">
						<ReactLoading type={'cylon'} color={'blue'} width={'15%'} height={'15%'}  />
					</div>
				</div>
        :
        <div></div>
        }
        <SectionTitle title="Files" />
        <Card className="file-manager file-manager-list p-0">
          <CardHeader className="text-center p-0">
            {/* Filters */}
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    onChange={this.handlePageSizeChange}
                    value={this.state.pageSize}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                {/* <Col className="file-manager__filters__search d-flex">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.props.searchHandler} />
                  </InputGroup>
                </Col> */}
              </Row>
            </Container>

            {/* Dropzone */}
            {/* <FileDropzone /> */}

            {/* <ButtonGroup size="sm" className="d-table ml-auto">
            <Button theme="white" onClick={() => {}}>
              <i className="material-icons add_box"></i>
            </Button>
          </ButtonGroup> */}
          </CardHeader>

          {/* Data Table */}
          <CardBody className="p-0">
            <ReactTable
              columns={tableColumns}
              data={searchContents}
              pageSize={pageSize}
              showPageSizeOptions={false}
              resizable={false}
            />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  repo: state.repo.activeRepo,
  acct: state.acct.ACCT,
  usr: state.auth.USR,
  activeDir: state.repo.activeDir,
  // DOCS: state.repo.activeDir.DOCS,
  docsLoading: state.repo.activeDirContentsLoading,
  firstLoading: state.repo.activeDirFirstLoading,
  searchContents: state.repo.searchedContents,
  contents: state.repo.activeDirContents,
  docsDone: state.repo.activeDirContentsDone,
  toggleTips: state.layout.toggleHideTooltips,
  searchHandler: state.layout.navbarSearchHandler,
  activeDocType: state.upload.activeDocType,
  rootFolderLoading: state.repo.rootFolderLoading,
  activeDirLoading: state.repo.activeDirLoading
})

const mapDispatchToProps = dispatch => ({
  getRepo: (ID) => dispatch(fetchRepo(ID)),
  getRepoList: (ID) => dispatch(fetchRepoList(ID)),
  getRootFolder: (ID) => dispatch(fetchRootFolder(ID)),
  getSubfolders: (ID) => dispatch(fetchSubfolders(ID)),
  getDocuments: (ID) => dispatch(fetchDocuments(ID)),
  getFolderContents: (ID) => dispatch(fetchFolderContentsBatched(ID)),
  getAccount: () => dispatch(fetchAccount()),
  setDir: (ID) => dispatch(setActiveDirectory(ID)),
  setRepo: (ID) => dispatch(setActiveRepo(ID)),
  setContents: (FLDRS,DOCS) => dispatch(setActiveDirContents(FLDRS,DOCS)),
  SetBreadcrumbDirs: (FLDR) => dispatch(setDirectories(FLDR)),
  toggleAddDir: () => dispatch(toggleAddDirCollapse()),
  removeDir: (ID) => dispatch(removeDirectory(ID)),
  removeDoc: (ID) => dispatch(removeDocument(ID)),
  search: (ID) => dispatch(updateSearchList(ID)),
  resetSearch: () => dispatch(resetSearchList()),
  setSearchHandler: (func) => dispatch(setNavbarSearchHandler(func))
})

export default connect(mapStateToProps,mapDispatchToProps)(FileManagerList)
